
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.welcome {
    padding: 64px 44px;
    border-radius: 32px;
    background: $grey;
    position: relative;
    overflow: hidden;
    margin-bottom: 96px;
    margin-left: 0 !important;
    margin-right: 0 !important;

    p {
        color: $textGrey;
        font-size: 16px;
        line-height: 24px;
    }

    .title {
        font-size: 24px;
        line-height: 28px;
        color: $textMain;
        margin-bottom: 32px;
    }
}

.consultation-button {
    margin-top: 64px;
    margin-bottom: 16px;
}

.dino2_4 {
    position: absolute;
    bottom: -50px;
    right: -43px;
    width: 100%;
    max-width: 461px;

    .title {
        position: absolute;
        bottom: 186px;
        left: -93px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $textGrey;
        z-index: 1;
    }
    .bubble {
        position: absolute;
        bottom: 178px;
        left: -120px;
        transform: scale(-1, 1);
        width: 123px;

        svg {
            path {
                fill: $bgLightGrey;
            }
        }
    }
}

.border-container {
    box-shadow: 0 0 0 24px $grey;
    border-radius: 16px;
    margin: 112px 0;

    .content {
        padding: 40px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .title {
        font-size: 24px;
        line-height: 28px;
        color: $textMain;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .subtitle {
        color: $textGrey;
    }
}

.payment-directly-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $textMain;

    span {
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration-line: underline;
        color: $accentMain;
    }
}
