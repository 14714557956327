
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bgMain;
}

.supportBlock {
    position: absolute;
    bottom: 24px;
    right: 24px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}
