
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.progress {
    .step {
        height: 8px;
        width: 36.8px;
        background: $linesSeparator;

        &:first-child {
            border-radius: 8px 0 0 8px;
        }
        &:last-child {
            border-radius:  0 8px 8px 0;
        }

        &.processed {
            background-color: $accentMain;
        }
    }
}
