.dx-scheduler-all-day-table-cell,
.dx-scheduler-time-panel-cell,
.dx-scheduler-header-panel-cell,
.dx-scheduler-date-table-cell {
    height: 24px;
    width: 163.29px;
}
.dx-item,
.dx-scheduler-appointment-vertical,
.dx-resizable .dx-state-hover {
    box-shadow: none !important;
}
.dx-scheduler-appointment {
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: initial;

    .slot-container {
        padding: 5px 8px;
        border-radius: 8px;

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: inherit;
        }
    }
    .appointment-diagnostic {
        background-color: $accentMain !important;
        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $white;
        }
    }
    :hover {
        box-shadow: none;
    }

    &.dx-state-focused:not(.dx-scheduler-appointment-drag-source)::before {
        background-color: initial;
    }
}

.dx-scheduler-appointment-content-details {
    margin: 0;
}

.dx-scheduler-appointment-content {
    padding: 0;
}

.dx-resizable-handle-top {
    display: none;
}
.dx-scheduler-group-header,
.dx-scheduler-header-panel,
.dx-scheduler-time-panel {
    font-size: 12px;
}

.dx-scheduler-view-switcher {
    display: none;
}

.dx-popover-arrow {
    display: none;
}
.dx-overlay-content,
.dx-popup-normal .dx-popup-inherit-height .dx-resizable {
    border-radius: 16px !important;
    padding: 16px !important;
}
.dx-list-item {
    border-top: none;
    color: none;
}

.dx-item-content:hover .dx-list-item-content:hover {
    background-color: transparent;
}

.dx-scheduler-appointment-collector.dx-button.dx-button-default,
.dx-scheduler-appointment-collector {
    border-radius: 8px;
    max-width: 120px !important;
    display: flex;
    align-items: center;
    margin-left: 7px;
}
.dx-scheduler-date-time-indicator {
    display: none;
}
