
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $textMain;
    font-size: 16px;
    line-height: 24px;
    margin: auto;
    text-align: center;
    max-width: 900px;
}

.setting-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
}

.setting-description {
    color: $textGrey;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
}

.input {
    padding: 12px 16px;
    border: 1px solid $border;
    border-radius: 8px;
    width: 84px;
    font-size: 16px;
    line-height: 24px;
}

.recommendation-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    line-height: 24px;
}
