
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: $zVideoConference;
    background-color: $bgMain;
}

.loaderWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.conference {
    display: flex;
    width: 100%;
}

.room {
    flex-grow: 1;
    overflow-y: auto;
}

// ================= Host admin aside
.hostAside {
    flex: 0 0 360px;
    // box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    filter: drop-shadow(0px 0px 24px rgba(168, 168, 168, 0.12));
    background-color: $white;
    overflow: hidden;
}

.fullScreenVideo {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: gray;
    justify-content: center;
    align-items: center;

    :global {
        .kurento-participant-video {
            max-height: 100%;
        }
    }
}

.shrink {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 8px !important;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
