
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100vh;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.nonActive {
    color: $red;
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

.eventBottomBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;
}

.statusField {
    margin-left: 8px;
}

.statusSuccess {
    padding: 4px 8px;
    background: $yellowBg;
    border-radius: 8px;
}

.statusError {
    padding: 4px 8px;
    background: $redBg;
    border-radius: 8px;
}

.statusInfo {
    padding: 4px 8px;
    background: $grey;
    border-radius: 8px;
}

.infoField {
    justify-content: space-between;
    padding: 24px;
    width: 100%;

    div:first-child {
        margin-right: 20px;

        > svg {
            width: 48px;
            height: 48px;
        }
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    p:first-child {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textLight;
    }
}

.secondaryField {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    color: $textGrey !important;
}

.checkButton {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;
}

.circleGreen {
    width: 48px;
    height: 48px;
    background: #9bf5af;
    border-radius: 100px;
}

.avatar {
    width: 48px;
    height: 48px;
    background: $blueLight;
    border-radius: 50%;
    overflow: hidden;

    svg {
        padding: 15px 17px 15px 17px;
    }

    img {
        width: 48px;
        height: 48px;
        object-fit: cover;
    }
}

.nameText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $accentMain;
}

.nameSubtext {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $textGrey !important;
}

.reasonBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }
}

.reasonText {
    margin-top: 48px;
    margin-bottom: 16px;
}

.loadedDocumentsText {
    margin-top: 48px;
}

.documentsBlock {
    justify-content: space-between;
    background: $bgHighlightGrey;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }
}

.documentsBlockCompleted {
    justify-content: space-between;
    background: $grey;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }
}

.courseBlockCompleted {
    justify-content: space-between;
    background: $white;
    border-radius: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }
}

.recommendationBlockCompleted {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }
}

.documentButton {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;

    svg {
        width: 24px !important;
        height: 24px !important;
    }
}

.documentLabel {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.chatBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    p {
        padding-top: 16px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: $textMain;
    }

    .chatWrapper {
        height: 70vh;
    }

    .chatHeader {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 16px;
        box-shadow: 0px 8px 8px rgba(27, 29, 33, 0.05);
    }

    .chatHeaderButton {
        padding: 6px 24px;
        background: $grey;
        border-radius: 4px;
        color: $textMain;
        height: auto;
        font-size: 14px;
        line-height: 20px;
        flex: 1 0 auto;

        &.active {
            background: $accentVeryLight;
        }
    }
}

.dateText {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $textMain !important;
}

.dateTextSmall {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain !important;
}

.managerLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.managerName {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.nextButton {
    width: 32px;
    height: 32px;
    padding: 0 !important;
    align-self: center;

    svg {
        width: 24px !important;
        height: 24px !important;
    }
}

.doctorDetailsButton {
    padding: 0;
    align-self: center;

    svg {
        width: 16px !important;
        height: 16px !important;
        margin-left: 8px;
        vertical-align: text-top;
    }
}

.newPatient {
    margin-right: 16px;
    align-self: center;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    align-items: center !important;
    color: $red !important;
}

.changeReasonBlock {
    padding: 24px;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    .fieldLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
    }

    :global {
        .ant-form-item {
            margin-bottom: 0;
        }
    }

    .descriptionField {
        margin-top: 24px;
        margin-bottom: 48px;
    }
}

.complaintField {
    margin: 0;

    p {
        display: inline-block;
        margin-left: 16px;
    }
}

.returnMoneyText {
    align-self: center;
    max-width: 400px;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: right !important;
    color: $textGrey !important;
}

.cancelReasonBlock {
    margin-top: 32px;
    margin-left: 64px;
    display: flex;
    align-items: center;

    svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
    }

    p {
        margin-right: 4px !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: $textGrey;
    }
}

.infoBlock {
    margin-top: 24px;
    padding: 24px;
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    .infoField {
        margin-top: 28px;
        padding: 0;

        .infoLabel {
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: $textGrey;
        }

        .infoItem {
            margin-top: 12px;
            padding: 12px 16px;
            background: $bgLightGrey;
            border-radius: 8px;

            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $textMain;

            .itemType {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $textMain;
            }

            .itemDescription {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $textGrey;
                div:first-child {
                    margin-right: 0;
                }
            }

            svg {
                margin-right: 8px;
                width: 24px;
                height: 24px;
            }
        }
    }

    ul {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding-left: 20px;
        margin: 0;
    }
}

.infoTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textLight;
}

.startsOver {
    margin-bottom: 4px;
    align-self: center;
    padding: 2px 8px;
    border-radius: 12px;
    border: none;
    background-color: #fff0c8;
    white-space: nowrap;
    margin-left: 20px;
    top: -3px;
    font-size: 0.875rem;
}

.notFilled {
    font-size: 12px;
    color: $textGrey;
}
