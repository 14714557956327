
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.procedure_item {
    background-color: rgba(27, 29, 33, 0.1);
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.procedure_item_finished {
    background-color: $green;
}

.procedure_item_failed,
.procedure_item_timeOver {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
}

.progressBar {
    background-color: $yellow;
    height: 100%;
}
