
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    background-color: $bgMain;

    &.screenSharing {
        overflow: hidden;
    }
}

.fakeAside {
    flex: 0 0 320px;
}
.aside {
    position: fixed;
    height: 100vh;
    width: 320px;
    background-color: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    padding: 16px;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        height: 100%;
    }

    .steps,
    .actionsList {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.contentWrapper {
    width: calc(100% - 670px);
    flex: 1 0 auto;
    max-width: 952px;
    margin: 24px auto;
    padding: 40px;
    border-radius: 16px;
    background-color: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
}

.steps {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    .step {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px;
        color: $textMain;
        border-radius: 8px;
        cursor: pointer;

        &.active {
            background: $accentHighlight;
        }

        &.disabled {
            color: $textLight;
            opacity: 0.75;
            pointer-events: none;
        }
    }
}

.notificationModal {
    position: fixed;
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 1;
    right: 16px;
    bottom: 16px;
    padding: 24px;
    background: #fff0c8;
    box-shadow: 0px 0px 16px rgba(168, 168, 168, 0.12);
    border-radius: 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $textMain;

    .avatar {
        background: $yellow;
        border-radius: 50%;
        padding: 12px;
        width: 48px;
        height: 48px;

        .icon {
            vertical-align: initial;
        }
    }
}

.viewOnly {
    &Wrapper {
        position: relative;
        width: 100%;
        display: flex;
    }

    &Aside {
        flex: 0 0 320px;
        width: 320px;
        padding: 16px;
    }

    &Steps {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        list-style: none;
        margin: 0;
        padding: 0;

        .step {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            color: $textMain;
            border-radius: 8px;
            cursor: pointer;
        }
    }

    &Content {
        flex: 1 1 auto;
        margin: 24px auto;
        padding: 0 0 40px;
    }
}

.screenVideoWrapper {
    flex-grow: 1;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: $zScreenSharingVideoFullScreen;

    .participant_screenSharing {
        background-color: $bgHighlightGrey;
        width: 100%;
        height: 100%;

        :global {
            video {
                max-height: 100vh;
            }
        }
    }

    .disableScreenBtn {
        position: absolute;
        bottom: 24px;
        right: 24px;
        padding: 0;
        width: 40px;
        height: 40px;
    }
}
