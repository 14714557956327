
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.error {
    color: $red;
}

.document {
    padding: 20px 20px 20px 16px;
    background: $grey;
    border-radius: 8px;
    color: $textMain;
    margin-bottom: 40px;
    cursor: pointer;
    strong {
        font-size: 1rem;
        font-weight: 500;
    }
    :global {
        .ant-space-item:nth-child(2) {
            flex-grow: 1;
        }
    }
    &:hover {
        background-color: darken($grey, 10%);
    }
}

.previewMask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: $zActiveProcedure;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
        width: 50%;
        height: 100%;
        min-width: 720px;
        display: block;
    }
    .closePreviewBtn {
        position: absolute;
        top: 12px;
        right: 15px;
        cursor: pointer;
    }
}

.warning {
    color: $textGrey;
    font-size: 0.875rem;
    margin-bottom: 40px;
    .alertIco {
        width: 25px;
        height: 25px;
    }
}
