
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.content {
    padding: 0 16px 16px 16px;
    hr {
        margin-top: 24px;
    }
}

.slotsBlock {
    margin-top: 24px;

    > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .slots {
        margin-top: 8px;
        display: flex;
        gap: 8px;
    }
}

.avatar {
    width: 48px;
    height: 48px;
    background: $blueLight;
    border-radius: 50%;
    overflow: hidden;

    svg {
        padding: 15px 17px 15px 17px;
    }

    img {
        width: 48px;
        height: 48px;
        object-fit: cover;
    }
}

.nameText {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $accentMain !important;
}

.changePrefix {
    width: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
}

.reasonBlock {
    margin-top: 40px;
}

.cancelReasonBlock {
    margin-top: 16px;
}

.cancelQuestionText {
    margin-top: 40px;
}

.commentBlock {
    margin-top: 32px;
}

.title {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.descriptionText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.slotContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.day {
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
}

.selected-time,
.time {
    padding: 10px 12px;
    border-radius: 8px;
    background-color: $bgHighlightGrey;
    cursor: pointer;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.selected-time {
    background-color: $accentHover;
    color: $white;
}


.changeDoctorListItem {
    box-shadow: none;
    border-radius: 0;

    &:not(:first-child) {
        padding-top: 24px !important;
    }
    &:not(:last-child) {
        padding-bottom: 24px !important;
    }
}