
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.document-container {
    display: flex;
    align-items: center;
    background-color: $bgLightGrey;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 8px;
    cursor: pointer;
}

.document-name {
    color: $textMain;
    font-size: 14px;
    line-height: 16px;
    margin-left: 13px;
}

.bg-blue {
    background-color: $blueVerifyLight;
}
