.photoBlock {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 4px;
    align-items: center;
    margin-right: 24px;
    width: 200px;
    height: 200px;
    background: rgb(197, 197, 197);
    border-radius: 600px;
    object-fit: cover;
}

.selfPreviewPhoto {
    position: absolute;
    top: 0;
    left: 0;
    overflow: visible;
    div {
        max-width: 100%;
    }
    img {
        margin: 0;
    }
}

.nonAvatarBlock {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 4px;
    align-items: center;
    margin-right: 24px;
    width: 200px;
    height: 200px;
    background: $bgLightBlue;
    border-radius: 600px;
}

h3 {
    margin-bottom: 16px !important;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.infoContainer {
    margin-top: 30px;
}

.infoItem {
    margin-top: 20px;
}

.infoTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textLight;
}

.infoValue {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.serviceInfoBlock {
    margin-top: 52px;
    padding: 24px 24px 32px 24px;
    border-radius: 8px;
    background-color: $grey;

    .chiefComplaints {
        margin-top: 4px;
        span {
            padding: 6px 8px 6px 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $textGrey !important;
        }
    }
}

.profileBlock {
    margin-top: 40px;

    .profileItem {
        margin-bottom: 24px;

        .title {
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }

        .profileRecord {
            display: flex;
            align-items: center;
            gap: 40px;
            margin-top: 8px;

            .profileRecordYear {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $textLight;
            }

            .profileRecordText {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $textMain;
            }
        }

        .profileRecordExperience {
            gap: 27px;
        }
    }
}

.infoBlocked {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 22px;
    margin-bottom: 10px;
    padding: 12px 32px 12px 24px;
    gap: 16px;

    width: 100%;
    max-width: 659px;
    background: $yellowBg;
    border-radius: 16px;

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    svg {
        width: 24px;
        height: 24px;
    }
}
