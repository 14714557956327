
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
}

.infoItem {
    margin-top: 20px;
    margin-bottom: -10px;
}

.infoTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textLight;
}

.infoValue {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.info {
    margin-top: 36px;
    svg {
        width: 27px;
        height: 27px;
    }
    .infoValue {
        margin-bottom: 0;
        font-size: 0.875rem;
        color: $textGrey;
    }
}
