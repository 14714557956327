// todo change it to CSS variables
:root {
    --main-bg-color: #fafbff;
    --primary-btn-bg-color: #235abe;
    --primary-btn-hover-bg: #4c83e5;

    --default-btn-color: #235abe;
    --default-btn-hover-bg: #caddff;
    --default-btn-hover-color: #4c83e5;
}

$bgMain: #fafbff;

$textMain: #1b1d21;

$textGrey: #505862;
$textLight: #8593a3;
$border: #c3ccd6;
$primary: #235abe;
$accentMain: #235abe;

$bgLightGrey: #f4f7fa;
$bgHighlightGrey: #edf3fe;
$bgLightBlue: #dee4eb;
$bgLightBlueBrighter: #d9e2ff;

$accentXx: #92B5F4;
$accentBlue: #6171ff;
$accentHover: #4c83e5;
$accentVeryLight: #caddff;
$accentHighlight: #edf3fe;
$red: #f64d4d;
$redDark: #cf2626;
$redBg: #ffdfdf;
$redLight: #fedbd4;
$redLightTransparent: #ef2f0527;
$white: #ffffff;
$linesSeparator: #dee4eb;
$grey: #eff2f5;
$green: #62c852;
$greenDark: #2a871c;
$yellow: #ffb876;
$purple: #bf3efc;
$blueVerifyLight: #d2f6ff;
$someBg: #dde9ff;
$blueLight: #a2eafc;
$greenLight: #cbfdc3;
$yellowBg: #fff0c8;
$lightRedBg: #ffdfdf;
$accentLight: #909bff;

$zConferenceButtons: 2;
$zScreenSharingVideo: 100;
$zColorController: 1000;
$zScheduleTooltip: 2000;
$zVideoConference: 9800;
$zActiveProcedure: 9900;
$zScreenSharingVideoFullScreen: 9910;
$zPauseOverlay: 9970;
$zNotification: 10200;
.ant-modal-mask {
    z-index: 10000 !important;
}
.ant-modal-wrap {
    z-index: 10100 !important;
}

$zGlobalLoader: 99999;
