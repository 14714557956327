
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 100%;
    height: 100%;
    padding: 24px;
}

.participants {
    width: 100%;
}

.participant_placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;
    padding: 80px 16px;
    background-color: $grey;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;

    &.in_lobby {
        background-color: $accentVeryLight;
    }

    .placeholder_title {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 8px 0;
    }

    .placeholder_subtitle {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0 0 18px 0;
        color: $textGrey;

        .placeholder_course {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $textMain;
            background: $white;
            border-radius: 16px;
            padding: 4px 12px;
        }
    }

    .placeholder_subtitle_first {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0 0 18px 0;
        color: $textLight;

        .placeholder_course {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $redDark;
            background: $redLight;
            border-radius: 16px;
            padding: 4px 12px;
        }
    }

    .placeholder_waiting {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $border;
        margin: 0;
    }

    &.selected {
        box-shadow: 0 0 0 6px #235abe;
    }

    &.compact {
        width: 204px;
        height: 152px;
        padding: 16px;
    }
}

.screenSharing {
    padding: 0;
    display: flex;
    flex-direction: column;
    max-width: calc(100vw - 350px);

    .screenVideoWrapper {
        flex-grow: 1;
        overflow: hidden;
        position: relative;

        .participant_screenSharing {
            background-color: $bgHighlightGrey;
            width: 100%;
            height: 100%;
        }

        .disableScreenBtn {
            position: absolute;
            bottom: 24px;
            right: 80px;
            padding: 0;
            width: 40px;
            height: 40px;
        }

        .openScreenSettingsBtn {
            position: absolute;
            bottom: 24px;
            right: 24px;
            padding: 0;
            width: 40px;
            height: 40px;
        }
    }

    .scrollX {
        overflow-x: auto;
        width: 100%;
        overflow-y: hidden;
        min-height: 200px;
        padding: 0 24px;

        .listWrapper {
            height: 100%;
        }
    }
}
