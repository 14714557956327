
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.textWrapper {
    font-size: 12pt;
    margin: 50px auto;
    width: 600px;
    font-family: 'Times New Roman', Times, serif;

    h2 {
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        font-size: 1em;
    }

    p {
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1em;
    }
}

.buttons {
    margin: auto;
    text-align: center;
}
