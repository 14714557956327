
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100vh;
}

.title {
    margin-left: 16px;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.account {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $textMain;
}

.accountBlock {
    margin-top: 32px;
    margin-left: 56px;
}

.accountInfo {
    margin-top: 30px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textLight;
}

.blockButton {
    margin-top: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ff604a;
    cursor: pointer;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    margin-bottom: 20px;
}

.calendarIcon {
    margin-right: 8px;
}

.navigationButtons {
    margin-top: 21.5px;
    margin-left: 44px;

    button {
        color: black;
        border: 0;
    }

    .selectedTab {
        color: $accentMain;
    }
}
