
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.grayBlock {
    background-color: $grey;

    .dino5r {
        text-align: right;

        svg {
            transform: scaleX(-1);
        }

        .dino {
            position: relative;
            right: 8px;
            margin-bottom: 0;
        }

        .bubble {
            position: absolute;
            width: 150px;
            height: 107px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 6px;
            right: 365px;

            svg path {
                fill: $bgLightGrey;
            }

            p {
                position: absolute;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.2rem;
                color: $textMain;
                z-index: 1;
                text-align: center;
            }
        }
    }
}

.consultationBlock {
    border-radius: 16px;
    margin-bottom: 64px;
    position: relative;
    overflow: hidden;
    padding: 64px;
}

.yellowBlock {
    padding: 37px 64px 37px 232px;
    background-color: $yellowBg;

    .dino5 {
        position: absolute;
        left: -102px;
        top: -60px;
        transform: scale(0.875) rotate(-18deg);
    }
}
