
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.courseRecommendedBlock {
    position: relative;
    display: flex;
    min-height: 224px;
    justify-content: space-between;
    overflow: hidden;
    padding: 80px 64px 32px 0;
    background: $yellowBg;
    border-radius: 32px;

    h2 {
        margin-bottom: 8px;
    }

    button {
        align-self: center;
        width: 320px;
        height: 64px;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        border-radius: 16px;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .courseInfo {
        max-width: 545px;
    }

    .dino {
        position: absolute;
        left: -113px;
        top: 0;

        svg {
            width: 300px;
            transform: matrix(0.94, -0.34, 0.33, 0.95, 0, -39);
        }

        .bubble {
            position: absolute;
            top: 0;
            left: 310px;

            .title {
                position: absolute;
                top: 16px;
                left: 44px;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: $textMain;
                z-index: 1;

                letter-spacing: 0.06em;
            }

            svg {
                width: 429px;
                height: 55px;
                opacity: 0.64;
                transform: none;

                path {
                    fill: $white;
                }
            }
        }
    }
}

.coursePayedBlock {
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 24px;
    background: $grey;
    border-radius: 32px;

    h3 {
        margin-bottom: 4px !important;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .payedBlock {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        svg {
            margin-left: 8px;
            padding: 4px;
            width: 24px;
            height: 24px;
        }
    }
}

.devicesBlock {
    margin-top: 40px;
    margin-bottom: 56px;
    padding: 24px;
    border-radius: 32px;

    hr {
        height: 8px;
        margin-top: 56px;
        margin-bottom: 32px;
        background: #eff2f5;
        opacity: 1;
        border: none;
    }
}

.deviceBlock {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    h3 {
        margin-bottom: 4px !important;
    }

    .deviceTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .confirmBlock {
        display: flex;
        align-items: center;

        button {
            width: 320px;
            height: 56px;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            border-radius: 16px;
        }
    }

    .gadgetShopButton {
        height: 20px;
        padding: 0;
        align-self: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        svg {
            width: 16px !important;
            height: 16px !important;
            margin-left: 8px;
            vertical-align: text-top;
        }
    }
}

.treatmentKitDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #505862;
}

.deliveryAddress {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #1B1D21;
    margin-top: 20px;
    margin-bottom: 0;
}

.addressDefinition {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #505862;
}

.treatmentKitImage {
    width: 176px;
    height: 136px;
    border-radius: 8px;
}