
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
@import '../../shared-layout.styles.scss';

.header {
    .nav-item {
        padding: 8px 10px;
    }
}

.badge {
    :global {
        .ant-badge-multiple-words {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}
