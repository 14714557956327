
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    .title {
        font-size: 1.5rem;
        line-height: 28px;
        color: $textMain;
        margin-bottom: 40px;
    }
    .subtitle {
        font-size: 1.25rem;
        line-height: 28px;
        color: $textMain;
        margin-bottom: 16px;
        svg {
            margin-top: -4px;
        }
    }
    .description {
        font-weight: 400;
        font-size: 0.875rem;
        margin-bottom: 40px;
    }
    .directly-payment-block {
        max-width: 696px;
        margin: 40px 0;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 24px;
        border-radius: 16px;
        background: var(--yellow-light, #fff0c8);

        .warning-icon {
            padding: 12px;
            border-radius: 40px;
            background: var(--yellow, #ffb876);

            svg {
                width: 24px;
                height: 24px;
            }
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: $textMain;
        }

        span {
            cursor: pointer;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration-line: underline;
            color: $accentMain;
        }
    }
}
