
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.chatBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    .headerInfo {
        padding: 16px 16px 0;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: $textMain;
    }

    .chatWrapper {
        height: 70vh;
    }

    .chatHeader {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 16px;
        box-shadow: 0px 8px 8px rgba(27, 29, 33, 0.05);
    }

    .chatHeaderButton {
        padding: 6px 16px;
        background: $grey;
        border-radius: 4px;
        color: $textMain !important;
        height: auto;
        font-size: 14px !important;
        line-height: 20px !important;
        flex: 1 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: max-content;

        &.active {
            background: $accentVeryLight;
        }

        .alertCircle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $red;
            margin-left: 4px;
        }
    }

    .small {
        max-width: 118px;
        padding: 6px 16px;
        overflow: hidden;
        background: $grey;
        border-radius: 4px;
        color: $textMain !important;
        height: auto;
        font-size: 14px !important;
        line-height: 20px !important;

        span {
            display: block;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &.active {
            background: $accentVeryLight;
        }
    }
}
