
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 80%;
    @media screen and (max-width: 575px) {
        width: 100%;
    }
    max-width: 720px;
    min-width: 240px;
    margin: auto;
    text-align: center;
}

.form {
    box-shadow: 0px 16px 32px rgba(23, 28, 72, 0.06);
    text-align: left;
}

.lightLabel {
    color: $textLight;
    position: relative;
    display: block;
    small {
        position: absolute;
        top: -33px;
        left: 0;
    }
}

.checkboxItem {
    margin-bottom: 0 !important;
    :global {
        .ant-form-item-explain-error {
            display: none;
        }
        .ant-checkbox-input[aria-invalid='true'] + .ant-checkbox-inner {
            border-color: $red;
        }
    }
}
.error {
    color: $red;
    position: absolute;
    padding-left: 40px;
}

.mainBlock {
    margin-bottom: 5px;
}

.bottomSection {
    margin-top: 43px;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.smallCheckBox {
    :global {
        .ant-checkbox-inner {
            width: 16px !important;
            height: 16px !important;
            &::after {
                height: 9px !important;
                width: 6px !important;
                margin-top: 0 !important;
                margin-left: 0 !important;
            }
        }
        .ant-checkbox + span {
            position: relative;
            top: 0 !important;
            padding-inline-start: 8px !important;
            padding-right: 0;
        }
    }
}

.documentLink {
    color: $primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 0;
}