.react-tel-input {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;

    input:disabled {
        background: rgba(0, 0, 0, 0.04);
        border-color: #C3CCD6;
        cursor: not-allowed;
    }

    .form-control {
        padding: 12px 16px 12px 60px;
        width: 100% !important;
        border: 1px solid #c3ccd6;
        border-radius: 8px;
    }

    .form-control:focus {
        padding: 12px 16px 12px 60px;
        border: 1px solid $accentBlue;
        box-shadow: none;
        border-radius: 8px;
    }

    .selected-flag.open:before {
        border-color: transparent;
        box-shadow: none;
    }

    .selected-flag.open:focus:before {
        border-color: transparent;
        box-shadow: none;
    }

    .country-list .country {
        display: flex;
        padding: 10px 9px 13px 46px;
    }
}
