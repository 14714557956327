
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 20px;
}