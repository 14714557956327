
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 40%;
    max-width: 400px;
    min-width: 240px;
    margin: auto;
    text-align: center;
    .restoreDescr {
        text-align: center;
        margin-bottom: 32px;
    }
}

.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;

    h4 {
        margin-bottom: 2rem;
    }
}

.logo {
    margin-bottom: 2.5rem;
}

.customLogo {
    margin-bottom: 2.5rem;
    width: 240px;
    max-height: 40px;
    object-fit: contain;
}

.bottomTxt {
    margin-top: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}
