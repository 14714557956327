
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.settingTitle {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 28px;
    color: $textMain;
    font-weight: 400;
}

.glasses-container {
    max-width: 80%;

    .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 24px;
        color: $textGrey;
    }
}

.dino2 {
    position: absolute;
    bottom: -40px;
    right: -5px;
    .bubble {
        position: absolute;
        top: 40px;
        right: 265px;

        .title {
            position: absolute;
            top: 16px;
            left: 32px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $textMain;
            z-index: 1;
        }
    }
}

.divider {
    background-color: $border;
    height: 1px;
    width: 100%;
}
