
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.consultationBlock {
    border-radius: 16px;
    margin-bottom: 64px;
    position: relative;
    overflow: hidden;
    padding: 64px;
}

.yellowBlock {
    padding: 37px 64px 37px 232px;
    background-color: $yellowBg;

    .dino5 {
        position: absolute;
        left: -102px;
        top: -60px;
        transform: scale(0.875) rotate(-18deg);
    }
}
