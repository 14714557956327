
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 1000px;
}

.list {
    color: $textGrey;
    font-size: 16px;
    line-height: 24px;
}

.tooltip {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin-bottom: 32px;
}

.infoBlock {
    padding-left: 24px;
}
