
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
}

.filter-btn {
    background-color: #eff2f5;
    border-radius: 4px;
    border: none;
    padding: 6px 16px;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    color: $textMain;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.last {
    font-size: 16px;
    line-height: 20px;
    color: $textMain;
    margin: 0;
}

.active {
    background-color: $accentVeryLight;
    font-weight: 500;
}

.schedule {
    background: $white;
    box-shadow: 0px 0px 16px rgba(168, 168, 168, 0.12);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px 24px;

    &.past {
        filter: grayscale(0.9);
    }
}

.schedule-title {
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 0;
}

.schedule-subtitle {
    font-weight: 400;
    font-size: 0.875rem;
    color: $textLight;
}

.joinButtonCell {
    text-align: right;
}

.setting-btn {
    background: $grey;
    border-radius: 8px;
    border: none;
    padding: 16px;
    outline: none;
}

.icon {
    border-radius: 8px;
    margin-top: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
}

.time {
    font-size: 20px;
    line-height: 24px;
    padding-top: 12px;
}

.patients {
    :global {
        .ant-collapse-item {
            .ant-collapse-header,
            .ant-collapse-content-box {
                padding: 0;
                padding-block: 0 !important;
                width: fit-content;
            }
            .ant-collapse-header .ant-collapse-arrow {
                font-size: 13px;
            }
            .ant-collapse-content {
                padding-block-start: 16px !important;
            }
        }
        table td {
            padding: 0 8px;
        }
    }

    .patientName,
    .age {
        white-space: nowrap;
        padding: 0 6px;
    }

    .patientName {
        padding-left: 0;
        font-size: 14px;
        line-height: 20px;
        color: $accentMain;
    }

    .age {
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
    }

    .queueNum {
        padding: 4px 8px;
        border-radius: 12px;
        border: none;
        color: $textGrey;
        background-color: $grey;
        margin: 4px 0;
    }

    .redTag {
        color: $red;
        background-color: $lightRedBg;
    }
}

.startsOver {
    padding: 2px 16px;
    border-radius: 12px;
    border: none;
    background-color: #fff0c8;
    white-space: nowrap;
    margin-left: 8px;
    font-size: 0.875rem;
}

.statusCancelled {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    color: $textMain;
    background-color: $redBg;
}

.settings {
    align-items: center;
    background-color: $yellowBg;
    border-radius: 16px;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
    margin-top: 32px;
    margin-bottom: 32px;

    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    .subtitle {
        font-size: 14px;
        line-height: 20px;
    }
}

.small-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    box-shadow: 0px 4px 24px rgba(24, 41, 82, 0.06);
    border-radius: 8px;
    background-color: $white;
    margin-top: 24px;
    cursor: pointer;
    width: 100%;

    .title {
        color: $textMain;
        font-size: 14px;
        line-height: 20px;
    }
}

.today-notice {
    display: flex;
    align-items: center;
    background-color: $yellowBg;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin-bottom: 24px;
}

.today {
    position: absolute;
    bottom: -4px;
    left: 0;
    color: $accentMain;
    padding: 0;
    padding-left: 20px;
}

.noAppointmentsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

.noAppointments {
    color: $textLight;
    font-size: 16px;
    line-height: 24px;
}

.filters {
    margin-bottom: 29px;
    margin-top: 5px;
}

.date {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.status {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $textMain;
    border-radius: 8px;
    padding: 4px 8px;
    margin-top: 12px;
    background-color: $linesSeparator;
}

.success {
    background-color: $greenLight;
}

.cancel {
    background-color: $redBg;
}

.hasMsgs {
    background-color: $redLight;
    outline: 11px solid $redLightTransparent;
    border-radius: 20px;
    overflow: visible;
    path {
        stroke: $red;
    }
    circle {
        fill: $red;
    }
    &.centered {
        margin: 43px auto 20px;
        display: block;
        cursor: pointer;
    }
}

.failIcon {
    max-width: 16px;
    max-height: 16px;
}

.progressIcon {
    max-width: 20px;
    max-height: 20px;
    stroke: #FF8A43;
}

.waitCheckDisplayInfo {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 4px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #FF8A43;
}

.blackColor {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $textMain;
}

.addressNotSpecified, .noCheck {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 4px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #F64D4D;
}

.notice {
    height: 32px;
    display: flex;
    align-items: center;
    background-color: $lightRedBg;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin-top: 8px;
    margin-left: -6px;
    column-gap: 8px;
}