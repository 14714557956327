
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $textMain;
    margin-bottom: 40px;
}

.selectedDate {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $textMain;
    margin-bottom: 24px;
}

.calendar-wrapper {
    max-width: 800px;
}

.day {
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
    margin-bottom: 8px;
}

.selected-time,
.time {
    padding: 10px 12px;
    border-radius: 8px;
    background-color: $bgHighlightGrey;
    cursor: pointer;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.selected-time {
    background-color: $accentHover;
    color: $white;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: $linesSeparator;
    margin: 24px 0;
}

.slots {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}
