
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    color: $textMain;
    width: 904px;
}

.actions {
    border-top: 1px solid $border;
}

.theme{
    margin: 40px 0;
    span {
        font-size: 16px;
        font-weight: 400;

    }
}

.themeButtonsContainer {
    background-color: #fafbff;
}

.themeButtons {
    background-color: #fafbff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.options {
    width: 216px;
}

.logoContainer {
    background-color: $white;
    padding: 12px;
    display: flex;
    justify-content: center;
}

.logo {
    width: 240px;
    height: 40px;
}

.logoRequiremenets {
    width: 758px;

    li {
        margin-bottom: 8px;
    }
}

.greyText {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $textGrey;
}

.customLogo {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.filledBtns {
    padding: 48px 24px;
}