
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.supportModal {
    :global {
        .ant-modal-content {
            .ant-modal-header,
            .ant-modal-footer {
                padding: 20px 40px;
            }
            .ant-modal-close {
                top: 24px;
                right: 24px;
            }
            .ant-modal-body {
                padding: 32px 40px;
                text-align: center;
            }
            .ant-modal-footer {
                padding-top: 24px;
                padding-bottom: 24px;
                .ant-btn {
                    width: 50%;
                }
                .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
                    margin-inline-start: 14px;
                }
            }
        }
    }
    p {
        color: $textMain;
    }
    .phone {
        font-size: 2rem;
        font-weight: 500;
        margin-top: 12px;
        line-height: 2rem;
    }
    .menuShot {
        width: 402px;
        height: 103px;
    }
}
