
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: fixed;
    left: 24px;
    top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: $zNotification;
}

.notification {
    display: flex;

    align-items: center;
    gap: 16px;
    padding: 12px 32px 12px 24px;
    background: $redBg;
    border-radius: 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $textMain;
    cursor: pointer;

    .icon {
        width: 24px;
        height: 24px;
    }
}
