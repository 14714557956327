
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.canvasWrapper,
.nonCanvasWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.nonCanvasWrapper {
    overflow-y: auto;
    background-color: #fff;
}

.canvas {
    display: block;
    width: 100%;
    height: 100%;
    min-width: 500px;
    min-height: 500px;
}
