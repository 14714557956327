
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: $textGrey;
    font-size: 16px;
    line-height: 24px;
    margin: auto;
    max-width: 1000px;
}

.setting-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
    color: $textMain;
}

.video {
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 16px;
}

.selector {
    border: none;
    color: $textLight;
    width: 100%;
}

.link {
    text-decoration: none;
    color: $accentHover;
}

.progressBar {
    width: 100%;
    margin: 16px 0;

    :global {
        .ant-progress-steps-item {
            width: 16px !important;
            height: 16px !important;
            background-color: $linesSeparator;
            border-radius: 2px;
            margin-right: 4px;
        }

        .ant-progress-steps-item-active {
            background-color: $accentLight;
        }
    }
    .ant-progress-steps-outer {
        width: 100%;
    }
}
