
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .contentWrapper {
        background-color: $white;
        box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
        border-radius: 16px;
        margin: 24px auto;
        padding: 40px;
        max-width: 952px;

        .base-check-btn {
            color: $white !important;
            width: 50%;
            margin-right: 8px;
            border: none;

            &:disabled {
                color: $textLight !important;
                background-color: $grey;
                pointer-events: none;
            }
        }

        .wrongBtn {
            @extend .base-check-btn;
            background-color: $red;

            &:hover {
                background-color: $redDark;
            }
        }

        .successBtn {
            @extend .base-check-btn;
            background-color: $green;

            &:hover {
                background-color: $greenDark;
            }
        }

        .success {
            width: 100%;

            :global {
                .ant-select-selector {
                    background-color: $greenLight !important;
                }
            }
        }
    }
}

.divider {
    background-color: $linesSeparator;
    width: 1px;
    height: 100%;
}

.left {
    padding-right: 40px;
}

.title {
    color: $textMain;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    margin-bottom: 40px;
}

.label {
    color: $textGrey;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.diagnostic-wrapper {
    .left-label {
        color: $textMain;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    .right-label {
        color: $textGrey;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        .bold {
            font-weight: 700;
            font-size: 12px;
        }
    }
}

.selectors-content {
    margin-bottom: 40px;
}

.diagnostic-configuration {
    margin: auto;
    width: 320px;
    margin-bottom: 40px;
    position: relative;
}

.indicator {
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    position: absolute;
    right: 50%;
    bottom: 65px;
}

.diagnostic {
    background-color: $bgLightGrey;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    padding: 24px 8px;
    color: $border;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: 218px;
    margin: auto;
    margin-bottom: 16px;

    &.active {
        background-color: $white;
        color: $textMain;
        border: 1px solid $bgLightBlue;
    }
}

.diagnosticResultText {
    color: $textMain !important;
}
