
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.spaceTitle {
    margin-bottom: 40px;
}

.list {
    margin-left: -24px;
    margin-right: -24px;
    padding: 4px 24px 4px 24px;
    :global {
        .rc-virtual-list-scrollbar {
            display: block !important;
        }
    }


    .listItem {
        padding: 8px 16 px;
        background-color: $white;
        min-height: 96px;
        box-shadow: 0px 0px 12px rgba(168, 168, 168, 0.12);
        border-radius: 8px;
        border-width: 0;
        border-block-end: none;
        overflow: hidden;

        .chooseBtn {
            margin-left: 24px;
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    
}

.link {
    padding: 0;
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.avatar {
    margin-right: 8px;
}
h4 {
    margin-bottom: 0 !important;
}
.doctorTitle {
    color: $textMain !important;
    font-weight: 500;
    font-size: 1rem;
    a {
        white-space: nowrap;
        font-size: 0.875rem;
        svg {
            font-size: 12px;
        }
    }
}
.subtitle {
    color: $textMain !important;
    white-space: nowrap;
    line-height: 20px;
}
.clinic {
    color: $textGrey !important;
    white-space: nowrap;
    line-height: 20px;
}
.price {
    font-weight: 500;
}
