
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    color: $textMain;

    :global {
        .ant-collapse {
            .ant-collapse-item {
                .ant-collapse-header {
                    padding: 0;
                }
            }
        }
        .ant-collapse-borderless .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
            padding: 20px 0;
        }
    }
}

.document-container {
    display: flex;
    align-items: center;
    background-color: $bgLightGrey;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 8px;
    cursor: pointer;
}

.document-name {
    color: $textMain;
    font-size: 14px;
    line-height: 16px;
    margin-left: 13px;
}

.formTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.active {
    transform: rotate(180deg);
    transition: all 0.4s;
}

.nonActive {
    transform: rotate(0);
    transition: all 0.4s;
}

.collapse {
    background: $white;
}

.duration {
    padding: 4px 16px;
    background: #d2f6ff;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin-bottom: 8px;
}

.warning {
    display: flex;
    align-items: center;
    p {
        font-size: 12px;
        line-height: 16px;
    }

    .title {
        color: $red;
    }
}

.bubble {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bgLightGrey;
    padding: 8px 8px 8px 16px;
    border-radius: 8px;
    cursor: pointer;

    .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        font-weight: 400;
    }
}

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.subtitle {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $textLight;
}

.touchable {
    cursor: pointer;
}

.relationship {
    margin-bottom: 20px;
}

.edit {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.badge {
    position: absolute;
    top: 5px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $redDark;
}

.listWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.uploadLabel {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $textGrey;
    margin-bottom: 16px;
}
