
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.form-container {
    padding: 12px 16px;
    border-radius: 8px;
    background-color: $bgLightGrey;
    margin-bottom: 8px;
}

.form-new {
    background-color: $blueVerifyLight;
}

.form-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.form-subtitle {
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}
