
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    margin: 0 auto 40px;
    max-width: 1240px;
    padding: 48px 20px 0;

    .backBtn {
        margin-right: 16px;
    }

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: $textMain;
        margin: 0;
    }
}

.headerBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 24px;
    padding: 0 56px;

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
        padding: 40px 0;
        list-style: none;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;

        li {
            &:before {
                content: '';
                background-color: #caddff;
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                vertical-align: text-bottom;
                margin-right: 16px;
            }
        }
    }

    .dinoBlock {
        position: relative;

        .bubble {
            position: absolute;
            max-width: 150px;
            transform: rotate(45deg);
            top: 30px;
            left: -155px;

            .title {
                position: absolute;
                top: 22px;
                left: 29px;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #505862;
                text-align: center;
                transform: rotate(-20deg);
                z-index: 1;
            }

            svg {
                transform: rotate(155deg);

                path {
                    fill: $bgLightGrey;
                }
            }
        }
    }
}

.mainList {
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 16px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 24px;
    transition: opacity 0.3s;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;

        .dayLabel {
            background-color: $grey;
        }
    }

    &.expanded {
        align-items: flex-start;
    }

    .labelPart {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &.finishedDayRow {
        opacity: 0.5;

        .editBtn {
            display: none;
        }
    }

    .valuePart {
        display: flex;
        align-items: flex-start;
        z-index: 1;
        gap: 32px;

        &.approved {
            align-items: center;
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid $linesSeparator;
    }
}

.dayLabel {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    width: 88px;
    height: 40px;
    border-radius: 41px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    background-color: $accentVeryLight;
    color: $textGrey;

    &.disabled {
        background-color: $grey;
        color: $textLight;
    }

    &.approved {
        background-color: $greenLight;
    }
}

.dayType {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $textMain;

    display: flex;
    flex-direction: column;

    .doctorName {
        font-size: 16px;
    }
}

.dayTypeIcon {
    margin: 0 20px;
}

.datepicker {
    width: 280px;

    .approvedDate {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;

        .approvedDay {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $textMain;
            margin-right: 8px;
        }
    }
}

.timepicker {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 330px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    .approvedTime {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    .time {
        display: flex;
        padding: 10px 12px;
        border-radius: 8px;
        background-color: $bgHighlightGrey;
        cursor: pointer;
        color: $textMain;

        &.selected {
            background-color: $accentHover;
            color: $white;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }
}

.actionsCell {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 100px;
    text-align: right;

    .editBtn {
        display: inline-flex;
        padding: 7px;
        height: auto;
        align-self: flex-end;
    }
}

.dinoWarning {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 26px;
    margin-bottom: -20px;
    margin-top: -50px;
    width: 100%;

    .dinoIcon {
        transform: scaleX(-1);
    }

    .bubble {
        position: relative;
        background-color: $yellowBg;
        border-radius: 16px 16px 0px 0px;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
        padding: 12px 24px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 40%;
            width: 0;
            height: 0;
            border: 15px solid transparent;
            border-right-color: $yellowBg;
            border-left: 0;
            margin-top: -15px;
            margin-left: -15px;
        }
    }
}

.callCenterNotice {
    display: flex;
    align-items: center;
    background-color: $yellowBg;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin: 24px 0;

    .subMessage {
        font-size: 14px;
        color: $textMain;
    }
}

.iconRedColor {
    fill: $red;
}

.slotSaveBtn {
    height: 40px;
    padding: 6px 24px !important;
}

.docAvatar {
    border-radius: 50%;
    object-fit: cover;
    width: 48px;
    height: 48px;
}

.cancelledText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $red;
}

.finishedText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $greenDark;
}

.cancelledBg {
    background-color: $redLight !important;
}

.finishedBg {
    background-color: $green !important;
}
