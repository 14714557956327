
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.requsitesForm {
    width: 592px;
}

.actions {
    border-top: 1px solid $border;
    margin-top: 40px;
}

.recordLabel {
    color: $textLight;
    font-weight: 500;
}

.content {
    width: 592px;
}