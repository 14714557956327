
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
@import '../../../shared-sessions.styles.scss';

.wrapper {
}

.downArrow {
    transform: rotate(90deg);

    path {
        stroke: $textGrey;
    }
}

.occlusionDays {
    .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        width: 80px;
    }

    .radioGroup {
        align-items: center;
    }
}

.procedureSettingsTable {
    min-width: 60%;
    max-width: 80%;
    table-layout: fixed;
    margin: auto;

    th {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        text-align: center;
    }

    td {
        padding: 4px 8px;
        min-width: 150px;
    }

    .rowLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
    }
}

.proceduresList {
    border: none;
    background-color: initial;

    .panelLabel {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;

        &.inactive {
            color: $textLight;
        }
    }

    .settingsLabel {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        min-height: 20px;
    }

    :global {
        .ant-checkbox-wrapper {
            align-items: center;
            margin: 0;

            .ant-checkbox + span {
                top: initial;
            }
        }

        .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
        }

        .ant-collapse-item {
            border-bottom: none;
            background-color: $grey;
            border-radius: 8px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &:last-child {
                border-radius: 8px;
            }

            .ant-collapse-header {
                padding: 14px 24px;
                align-items: center;
                user-select: none;

                .ant-collapse-header-text {
                    width: 100%;
                }

                .ant-collapse-expand-icon {
                    cursor: pointer;
                }
            }

            .ant-form-item {
                margin: 0;

                .ant-checkbox {
                    top: initial;
                }
            }
        }

        .ant-collapse-content {
            border: none;

            .ant-collapse-content-box {
                padding: 0 24px 24px;
                background-color: $grey;
                border-radius: 0 0 8px 8px;
            }
        }
    }
}
