
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.border-container {
    box-shadow: 0 0 0 24px $grey;
    border-radius: 16px;
    margin: 112px 0;

    .content {
        padding: 40px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .title {
        font-size: 24px;
        line-height: 28px;
        color: $textMain;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .subtitle {
        color: $textGrey;
    }

    &.consultationBordered {
        margin-top: 64px;
        .title {
            margin-bottom: 36px;
        }
        .doctorBlock,
        .statusBlock {
            max-width: 320px;
            .title {
                font-size: 1rem;
                font-weight: 500;
            }
            .subtitle {
                line-height: 1rem;
            }
            .link {
                margin-bottom: 0;
            }
        }
        .flex4section {
            :global {
                .ant-space-item:nth-child(2),
                .ant-space-item:nth-child(4) {
                    flex-grow: 1;
                }
            }
        }
        .timeBlock {
            .date {
                font-weight: 500;
            }
            .time {
                font-size: 1.5rem;
            }
        }
        .bottomSection {
            margin-top: 40px;
            .title {
                margin-bottom: 24px;
            }
        }
    }
}

.big-divider {
    background-color: $grey;
    height: 24px;
    width: 100%;
}
