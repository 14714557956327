
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.modalTitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $textMain;
    margin-bottom: 8px;
}

.modalSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
    margin: 0;
}

.active {
    transform: rotate(180deg);
    transition: all 0.4s;
}

.nonActive {
    transform: rotate(0);
    transition: all 0.4s;
}

.collapse {
    background: $white;
}

.nurse {
    font-size: 12px;
    line-height: 16px;
    color: $textGrey;
}

.headerTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    margin-left: 8px;
}

.procedureName {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.duration {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $textGrey;
}

.comment {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.procedure-comment {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $redDark;
}
