
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.profileTitle {
    display: flex;
    h4 {
        margin-right: 28px;
        margin-bottom: 0;
    }
    svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
