
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 40%;
    max-width: 400px;
    min-width: 240px;
    margin: auto;
    text-align: center;
}

.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;
}

.customLogo {
    margin-bottom: 2.5rem;
    width: 240px;
    max-height: 40px;
    object-fit: contain;
}

.login {
    margin-bottom: 2rem;
}

// .gosuslugiBtn {
//     margin-top: 2rem;
//     margin-bottom: 2rem;
// }

.bottomTxt {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.esiaSection {
    margin-top: 32px;
}