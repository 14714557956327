
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    .title {
        font-size: 1.5rem;
        line-height: 28px;
        color: $textMain;
        margin-bottom: 40px;
    }
    .description {
        font-weight: 500;
        font-size: 0.875rem;
        margin-bottom: 21px;
        color: $textGrey;
    }

    .document {
        padding: 16px 24px 16px 16px;
        background: $bgHighlightGrey;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
        margin-bottom: 16px;
    }

    .link {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $accentMain;
    }

    .checkbox {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: $linesSeparator;
        margin: 27px 0;
    }

    .textArea {
        height: 88px;
    }
    button {
        padding-left: 24px;
        padding-right: 24px;
        &.closeBtn {
            padding: 0;
            height: auto;
            color: $textGrey;
        }
    }
    .fileContent {
        flex-grow: 1;
        padding: 0 16px;
        font-weight: 400;
        max-width: 100%;
        overflow: hidden;
        strong,
        div {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }
        strong {
            font-size: 1rem;
            font-weight: 500;
        }
    }
    .fileFormats {
        font-size: 0.875rem;
        font-weight: 400;
        color: $textGrey;
    }

    .spinner svg {
        width: 24px;
        height: 24px;
    }
}
