.wrapper {
    :global {
        .ant-checkbox-wrapper {
            margin: 0;
        }

        .ant-form-item {
            margin: 0;
            margin-bottom: 8px !important;
        }
    }
}

.photoBlock {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .photoUpload {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        min-width: 200px;
        height: 200px;
        background: rgb(0, 0, 0);
        border-radius: 600px;
        cursor: pointer;

        img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 200px;
            opacity: 0.5;
            border-radius: 600px;
            object-fit: cover;
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $white;
        }
    }
}

hr {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 24px;
    border: 1px solid #c3ccd6;
}

.infoContainer {
    margin-top: 30px;
}

.infoItem {
    margin-top: 20px;
}

.infoTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textLight;
}

.infoValue {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.cancelButton {
    margin-right: 16px;
}

h3 {
    margin-bottom: 16px !important;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.serviceInfoBlock {
    margin-top: 32px;
    padding: 24px 24px 32px 24px;
    border-radius: 8px;
    background-color: $grey;

    .chiefComplaints {
        margin-top: 4px;
        span {
            padding: 6px 8px 6px 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $textGrey !important;
        }
    }
}

.profileBlock {
    margin-top: 40px;

    .profileItem {
        margin-bottom: 24px;

        .title {
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }

        .profileRecord {
            display: flex;
            gap: 16px;
            margin-top: 8px;

            .profileRecordYear {
                margin-top: 4px;
                /* Body/14 Bold */
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $textLight;
            }
        }

        .profileRecordExperience {
            gap: 27px;
        }
    }
}

.addButton {
    height: 24px;
    padding: 0;

    svg {
        margin-right: 7px;
    }
}

.removeButton {
    padding-left: 6px;
}

.validationText {
    display: flex;
    align-items: center;

    svg {
        min-width: 18px;
        margin-right: 19px;
    }
}

.formContainer {
    :global {
        .ant-checkbox-wrapper {
            margin: 0;
        }

        .ant-form-item {
            margin: 0;
            margin-bottom: 8px !important;
        }
    }
}
