
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
}

.filterLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.patientRow {
    :global {
        .ant-pagination {
            background: white;
            justify-content: start;
            align-items: center;
            margin: 0 !important;
            padding: 16px;

            li:first-child {
                margin-right: auto;
            }

            li:last-child {
                margin-left: auto;
            }

            .ant-pagination-total-text {
                font-weight: 500;
                font-size: 14px;
                color: $textMain;
            }

            .ant-pagination-options {
                margin: 0;
            }

            .ant-pagination-options .ant-select-selector {
                /* Body/14 Bold */
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                color: $accentMain;
                border: none;
            }
        }
    }
}

.patientRow tr:hover td {
    background: $bgLightGrey;
    border-bottom: 1px solid $bgLightBlue;
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
    cursor: pointer;
}

.red {
    width: 12px;
    height: 12px;
    background-color: $red;
    border-radius: 50%;
}

.default {
    width: 12px;
    height: 12px;
    background-color: $bgLightGrey;
    border-radius: 50%;
}

.birthDay {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.chevronButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 4px;
}
