
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    transform: scale(1);
    transform-origin: center center;
    width: 100vw;
    height: 100vhc;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.subWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.mobileCenterContainer {
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 375px;
    width: 30%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.mobileCenterSubContainer {
    top: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 100px 75px 48px 75px;
}

.info {
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 24px;

    h4 {
        line-clamp: 2;
        text-align: center;
    }

    .notice {
        color: #505862;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;

    span {
        color: #505862;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .phone {
        color: #1B1D21;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}