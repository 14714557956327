
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.customLogo {
    width: 240px;
    max-height: 40px;
    object-fit: contain;
}

.logoMargin {
    margin-bottom: 2.5rem;
}