
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.modal {
    :global {
        .ant-modal-header {
            margin-bottom: 0;
        }

        .ant-upload-drag {
            background: #fafbff;
            border: 2px dashed #dee4eb;
            border-radius: 8px;
            padding: 0;
        }
    }
}

.content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    color: $textGrey;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: auto;
}

.upload {
    min-width: 160px;
}

.closeIcon {
    cursor: pointer;
}

.item {
    .itemHead {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;
        margin-bottom: 8px;
    }
}
.itemLabel {
    font-weight: 500;
    max-width: 476px;
    font-size: 14px;
    line-height: 16px;
    color: $textMain;
}

.dragger-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
    margin-top: 7px;
    margin-bottom: 0;
}
