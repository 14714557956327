
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
}

.subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
}

.content {
    max-width: 800px;
}

.files-wrapper {
    margin-top: 34px;
    margin-bottom: 42px;
}

.file-container {
    padding: 16px 24px 16px 16px;
    margin-bottom: 8px;
    background-color: $grey;
    border-radius: 8px;
}

.file-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.file-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;
}

.complect-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $textMain;
    margin-bottom: 16px;
}

.complect-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
    margin-bottom: 16px;
}

.complect-checked-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.recommendation {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: $yellowBg;
    margin-right: 16px;
}

.shop-link {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;
}

.info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.empty {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
    margin-top: 26px;
    padding: 40px 0px;
}

.icon {
    margin-right: 22px;
}

.deviceBlock {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    h3 {
        margin-bottom: 4px !important;
    }

    .deviceTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .confirmBlock {
        display: flex;
        align-items: center;

        button {
            width: 320px;
            height: 56px;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            border-radius: 16px;
        }
    }

    .gadgetShopButton {
        height: 20px;
        padding: 0;
        align-self: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        svg {
            width: 16px !important;
            height: 16px !important;
            margin-left: 8px;
            vertical-align: text-top;
        }
    }
}

.treatmentKitDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #505862;
}

.deliveryAddress {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #1B1D21;
    margin-top: 20px;
    margin-bottom: 0;
}

.addressDefinition {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #505862;
}

.treatmentKitImage {
    width: 176px;
    height: 136px;
    border-radius: 8px;
}