
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    .title {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $textMain;
        margin-bottom: 40px;
    }

    .document {
        padding: 16px 24px 16px 16px;
        background: $grey;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
        margin-bottom: 16px;
    }

    .link {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $accentMain;
    }

    .checkbox {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: $linesSeparator;
        margin: 27px 0;
    }
}
