
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.date {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.warning {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #cf2626;
}

.callCenter {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
}
.phoneNumber {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}
