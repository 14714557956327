
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100vh;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.appointmentsNavigation {
    margin-top: 24px;
    margin-bottom: 24px;

    a {
        padding: 8px 16px 8px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    a.active {
        border-bottom: 2px solid #6171ff;
        color: $textMain;
    }
}

.filterBlock {
    margin-top: 32px;
}

.bottomSection {
    margin-bottom: 12px !important;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.nonActive {
    color: $red;
}
