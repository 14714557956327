
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    margin-top: 40px;
    padding: 12px 32px 12px 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 16px;
    background-color: $blueVerifyLight;
    display: flex;
    gap: 16px;

    .message {
        color: $textMain;
    }

    .infoIcon {
        width: 24px;
        height: 24px;

       svg {
            color: $primary; 
       } 
    }
}