
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
// ================= Host admin aside
.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .title {
        padding: 12px 16px;
    }

    .aside_host {
        // flex: 1 0 auto;
    }

    .diagnostic_video {
        position: relative;
        overflow: hidden;
        height: 220px;

        :global {
            .kurento-participant-video {
                max-height: 100%;
            }
        }
        .host_preview {
            position: absolute;
            top: 0;
            left: 0;
            width: 30%;

            :global {
                .kurento-participant-video {
                    height: 100%;
                }
            }
        }

        .expand {
            position: absolute;
            top: 16px;
            right: 16px;
            padding: 8px;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .aside_content_wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .aside_header {
        position: sticky;
        top: 0;
        background-color: $white;
        box-shadow: 0px 8px 8px rgba(27, 29, 33, 0.05);
        display: flex;
        flex-direction: column;
        z-index: 1;
        padding: 0;

        .patientHeader {
            padding: 16px;
            border-bottom: solid 1px $grey;

            .darkCross {
                path {
                    stroke: $textLight;
                }
            }
        }

        .title {
            font-size: 0.875rem;
            font-weight: 500;
            color: $textMain;
            padding: 0;
        }

        .subTitle {
            font-size: 0.75rem;
            color: $textGrey;
        }
    }

    .profileIcon {
        margin-right: 8px;

        rect {
            fill: $bgHighlightGrey;
        }

        path {
            stroke: $accentLight;
        }
    }

    .patientTitle {
        flex-grow: 1;
    }

    .aside_content {
        flex: 1 1 auto;
        overflow: auto;
        position: relative;
    }

    .aside_general {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .general_header {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-size: 16px;
            line-height: 24px;

            .general_subtitle {
                font-weight: 400;
                color: $textGrey;
            }

            .general_title {
                font-weight: 500;
                color: $textMain;
            }
        }

        .general_patients {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $textGrey;

            > div {
                display: flex;
                justify-content: space-between;
            }

            .general_counter {
                font-weight: 500;
                color: $textMain;
            }
        }

        .general_buttons {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    .aside_tabs {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        padding: 8px 16px;
    }

    .aside_tabs_tab {
        padding: 6px;
        background-color: $grey;
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $textMain;
        border: none;
        box-shadow: none;
        outline: none;
        width: 100%;

        .badge {
            left: 3px;
            top: -7px;
        }
    }

    .active_tab {
        background-color: $accentVeryLight;
    }
}

.aside_procedures {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 16px;
    margin: 0;
    padding: 0;
}

.aside_procedures_header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    .occlusion {
        font-size: 14px;
        line-height: 20px;
        background: $blueVerifyLight;
        border-radius: 30px;
        padding: 4px 12px;
    }
}

.indicator {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: $red;
    box-shadow: 0px 0px 7px 4px rgba(243, 86, 37, 0.2);
}

.connectionTime {
    font-size: 1.25rem;
    color: $textGrey;
}

.closeBtn {
    width: 40px !important;
    height: 40px;
    padding: 0 !important;
    &,
    &:hover {
        color: $red !important;
    }
}

.proceduresContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .proceduresList {
        position: absolute;
        overflow: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        &.invisible {
            visibility: hidden;
        }
    }
}

.bubble {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bgLightGrey;
    padding: 8px 8px 8px 16px;
    border-radius: 8px;
    cursor: pointer;

    .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        font-weight: 400;
    }
}

.blueBubble {
    background-color: #EDF3FE !important;
    cursor: default;

    .commentDoctor {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: $textMain;
    }

    .comment-icon-space {
        margin-left: 11px;
    }
}