
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
@import '../../shared-layout.styles.scss';

.appointmentLink {
    height: 40px;
    padding: 8px 20px !important;
    svg {
        margin-right: 12px;
        margin-bottom: 5px;
        path {
            stroke: $primary;
        }
    }
}
.menu {
    flex-grow: 1;
}
.rightSection {
    margin-left: 24px;
}

.badge {
    :global {
        .ant-badge-multiple-words {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}
