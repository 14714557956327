
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
@import '../../../shared-sessions.styles.scss';

.targetEyeSelector {
    display: flex;
    align-items: center;
    gap: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;

    :global {
        .ant-checkbox-group {
            gap: 18px;

            .ant-checkbox + span {
                padding-inline-start: 8px;
            }
        }
    }
}

.propsBlock {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 24px;
}

.propsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    align-items: center;
    flex: 1 0 auto;

    > *:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    :global {
        .ant-form-item {
            margin: 0;
        }
    }

    .additionalField {
        grid-column: span 2;
    }
}

.addButton {
    height: 24px;
    padding: 0;

    svg {
        margin-right: 4px;
    }
}

.removeButton {
    padding-left: 6px;
}
