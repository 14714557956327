
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.consultationsForm {
    width: 592px;
    overflow: hidden;
}

.priceField {
    width: 160px;
    margin-bottom: 0 !important;
}

.prices {
    display: flex;
    gap: 32px;
}

.consultationRow {
    border-top: 1px solid $border;
    align-items: baseline;
    padding: 16px 0;
}

.actions {
    border-top: 1px solid $border;
}

.consultationTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.rubIcon {
    color: #8593A3;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.filledPrice {
    width: 160px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
}

.priceColumn {
    text-align: right;
    width: 160px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}


.pricesWrapper {
    display: flex;
    gap: 32px;
}

.categoryColumn {
    width: 208px;
}

.footerLabel {
    max-width: 322px;
}