
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.infoContainer {
    margin-top: 30px;
}

.infoItem {
    margin-top: 20px;
}

.infoTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textLight;
}

.infoValue {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.infoBlocked {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 32px 12px 24px;
    gap: 16px;

    width: 321px;
    height: 48px;
    background: $redBg;
    border-radius: 16px;

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    svg {
        width: 24px;
        height: 24px;
    }
}

.blockButton {
    margin-top: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #f64d4d;
}

.unblockButton {
    margin-top: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.accountTitle {
    display: flex;
    h4 {
        margin-right: 28px;
        margin-bottom: 0;
    }
    svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
