
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.actions {
    border-top: 1px solid $border;
    margin-top: 40px;
}

.uploadedDocuments {
    .document {
        padding: 16px 24px 16px 16px;
        background: $grey;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
        margin-bottom: 8px;
    }
    
    .link {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $accentMain;
    }
}

.content {
    width: 592px;

    .readonlyWarningMsg {
        width: 604px;
    }
}