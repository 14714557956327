
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .title {
        margin: 0;
    }
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .group {
        margin-bottom: 12px;

        .label {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $textLight;
        }

        .value {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $textMain;
        }
    }
}
