
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.collapse {
    border-radius: 8px;
    border: none;
    overflow: hidden;

    --backgroundColor: #eff2f5;

    &.status_inProgress,
    &.status_started,
    &.status_continued,
    &.status_finishedButNeedConfirmation,
    &.status_paused,
    &.status_waitingApproveToChangeGlasses {
        --backgroundColor: #fff0c8;
    }

    &.status_finished {
        --backgroundColor: #cbfdc3;
    }

    &.status_failed,
    &.status_timeOver {
        --backgroundColor: #ffdfdf;
    }

    :global {
        .ant-collapse-item {
            border-bottom: none;

            .ant-collapse-header {
                padding: 16px;
                align-items: center;
                user-select: none;

                .ant-collapse-header-text {
                    width: 100%;
                }

                .ant-collapse-expand-icon {
                    cursor: pointer;
                    margin-left: -30px;
                }
            }
        }

        .ant-collapse-content {
            border-color: rgba(27, 29, 33, 0.2);

            .ant-collapse-content-box {
                padding: 0;
                background-color: var(--backgroundColor);
            }
        }
    }

    background-color: var(--backgroundColor);

    .content {
        background-color: var(--backgroundColor);
    }

    &.disabled {
        filter: grayscale(1);
        opacity: 0.6;
        pointer-events: none;
    }
}

.header {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1b1d21;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 32px);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 16px;
}

.settings_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #505862;
}

.actions_row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action_buttons {
        display: flex;
        gap: 4px;
    }

    .progress_block {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textMain;
        text-align: right;
        display: flex;
        align-items: center;
        gap: 8px;

        &.inactive {
            opacity: 0.4;
        }

        // .fails_count {
        //     color: $red;
        // }

        // .success_count {
        //     color: $green;
        // }

        // .target_count {
        //     color: #1b1d21;
        // }

        .progressIcon {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }

        .time {
            display: flex;
            align-items: center;
        }
    }

    .divider {
        display: block;
        width: 2px;
        align-self: stretch;
        background: #1b1d21;
        opacity: 0.1;
    }
}

.comment_row {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.comment_block {
    padding: 8px 8px 8px 16px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .comment_label {
        color: #505862;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        max-width: 80%;
        text-overflow: ellipsis;
    }
}

.startStopBtn {
    width: 40px;
    height: 40px;
    background: rgba(80, 88, 98, 0.1);
    border-radius: 40px;
    color: $textGrey;
    padding: 0;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: none;
    border: none;

    &.active {
        background-color: $accentMain;
        color: $white;
    }

    &:disabled {
        opacity: 0.2;
    }
}

.settingsIcon {
    min-width: 24px;

    path {
        fill: $textLight;
    }

    &.disabled {
        filter: grayscale(1);
        opacity: 0.6;
        pointer-events: none;
    }
}

.accommodation {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 24px;

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
        padding: 8px 0;
    }

    .value {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $textMain;
        padding: 8px 0;

        &.forEdit {
            background: rgba(255, 255, 255, 0.4);
            border-radius: 8px;
            position: relative;

            .editBtn {
                position: absolute;
                top: 8px;
                right: 8px;
                z-index: 1;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}

.toggleInputWrapper {
    position: relative;

    input {
        text-align: center;
        height: 40px;
        user-select: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    .minusBtn {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
        cursor: pointer;
        user-select: none;
    }

    .plusBtn {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
        cursor: pointer;
        user-select: none;
    }
}
