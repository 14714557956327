
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.dinoReady {
    margin-top: -186px;
    //
    position: absolute;
    right: calc(100% + (100vw - 100%) / 2 - 168px);
    width: 806px;
    height: 455px;
    :global {
        svg#dinoReady {
            transform: scaleX(-1);
            width: 806px;
            height: 455px;

            #dino2shadow {
                display: none;
            }
        }
    }
    .bubble {
        position: absolute;
        right: -111px;
        top: 197px;
        display: flex;
        padding: 0 8px;
        align-items: center;
        height: 87px;
        justify-content: center;
        width: 106px;
        transform: rotate(4.13deg);
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            path {
                fill: $bgMain;
            }
        }
        p {
            position: relative;
            z-index: 1;
            line-height: 1.125rem;
        }
    }
}

.procedure-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
}
