
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.previewMask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: $zActiveProcedure;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
        width: 50%;
        height: 100%;
        min-width: 720px;
        display: block;
    }
    img {
        max-height: 100%;
    }
    .closePreviewBtn {
        position: absolute;
        top: 12px;
        right: 15px;
        cursor: pointer;
    }
}
