
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100vh;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.filterBlock {
    margin-top: 32px;
}

.bottomSection {
    margin-bottom: 12px !important;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.status {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $border;

    &.ACTIVE {
        color: $textMain;
    }

    &.BLOCKED {
        color: $red;
    }

    &.DRAFT {
        color: $textLight;
    }
}

.employeeRow {
    :global {
        .ant-pagination {
            background: white;
            justify-content: start;
            align-items: center;
            margin: 0 !important;
            padding: 16px;

            li:first-child {
                margin-right: auto;
            }

            li:last-child {
                margin-left: auto;
            }

            .ant-pagination-total-text {
                font-weight: 500;
                font-size: 14px;
                color: $textMain;
            }

            .ant-pagination-options {
                margin: 0;
            }

            .ant-pagination-options .ant-select-selector {
                /* Body/14 Bold */
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                color: $accentMain;
                border: none;
            }
        }
    }
}

.employeeRow tr:hover td {
    background: $bgLightGrey;
    border-bottom: 1px solid $bgLightBlue;
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
    cursor: pointer;
}

.smallCheckBox {
    :global {
        .ant-checkbox-inner {
            width: 16px !important;
            height: 16px !important;
            &::after {
                height: 9px !important;
                width: 6px !important;
                margin-top: 0 !important;
                margin-left: 0 !important;
            }
        }
        .ant-checkbox + span {
            position: relative;
            top: 0 !important;
            padding-inline-start: 8px !important;
            padding-right: 0;
        }
    }
}

.checkboxItem {
    margin-bottom: 0 !important;

    :global {
        .ant-form-item-explain-error {
            display: none;
        }
        .ant-checkbox-input[aria-invalid='true'] + .ant-checkbox-inner {
            border-color: $red;
        }
    }
}
