
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.footer {
    background: $grey;
    padding: 14px 0;
}

.link {
    color: $textLight;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.divider {
    background-color: $textLight;
    margin: 0 5px;
    height: 16px;
    width: 1.5px;
}

.supportBlock {
    text-align: center;
    color: $textGrey;
    margin-bottom: 40px;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textLight;
}
