
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.contentWrapper {
    max-width: 1300px;
    margin: 0 auto 32px;
}

.menu {
    list-style-type: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    
    li {
        padding: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &.active {
            background: $accentHighlight;
        }

        &.processed {
            .stepIcon {
                fill: $accentXx;
            }
        }

        &.readonly {
            .stepIcon {
                fill: $textGrey;
            }
        }

        .lockIcon {
            fill: $white;
        }

        svg {
            fill: $linesSeparator;
            font-size: 16px;
        }
        
        span {
            vertical-align: middle;
        }
    }

    .progressBar {
        background-color: $white;
        border-radius: 8px;
    }
    
    .content {
        gap: 72px;
    }    
}
