
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
@import '../shared-edit-profile-details.styles.scss';

.checkboxGroupColumn {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
