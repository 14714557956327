
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.control-button {
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: $grey;
    color: $textGrey;
    font-size: 14;
    font-weight: 500;
}

.color-label {
    color: $textMain;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

input[type='range']:focus {
    outline: none;
}

.redInput[type='range']::-webkit-slider-runnable-track {
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: $red;
    border-radius: 5px;
}

.redInput[type='range']::-webkit-slider-thumb {
    width: 10px;
    height: 15px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
}

.blueInput[type='range']::-webkit-slider-runnable-track {
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: $accentBlue;
    border-radius: 5px;
}

.blueInput[type='range']::-webkit-slider-thumb {
    cursor: pointer;
    -webkit-appearance: none;
    width: 10px;
    height: 15px;
    margin-top: -4px;
}

.greenInput[type='range']::-webkit-slider-runnable-track {
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: $green;
    border-radius: 5px;
}

.greenInput[type='range']::-webkit-slider-thumb {
    cursor: pointer;
    -webkit-appearance: none;
    width: 10px;
    height: 15px;
    margin-top: -4px;
}

.readyButton {
    width: 100%;
    margin-top: 40px;
}
