
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
}

.filter-btn {
    background-color: #eff2f5;
    border-radius: 4px;
    border: none;
    padding: 6px 16px;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
    white-space: nowrap;
}

.active {
    background-color: $accentVeryLight;
}

.schedule {
    background: $white;
    box-shadow: 0px 0px 16px rgba(168, 168, 168, 0.12);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px 24px;

    &.past {
        filter: grayscale(0.9);
    }
}

.schedule-title {
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 0;
}

.schedule-subtitle {
    font-weight: 400;
    font-size: 0.875rem;
    color: $textLight;
}

.joinButtonCell {
    text-align: right;
}

.setting-btn {
    background: $grey;
    border-radius: 8px;
    border: none;
    padding: 16px;
    outline: none;
}

.icon {
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-right: 28px;
}

.therapyIcon {
    background: $greenLight;
}

.diagnosticIcon {
    background: $yellowBg;
}

.docName,
.doctorLabel,
.time {
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.doctorLabel {
    margin-right: 4px;
}

.time {
    margin-right: 16px;
}

.docName {
    color: $textMain;
}

.startsOver {
    padding: 2px 16px;
    border-radius: 12px;
    border: none;
    background-color: #fff0c8;
    white-space: nowrap;
    position: absolute;
    left: 100%;
    margin-left: 20px;
    top: -3px;
    font-size: 0.875rem;
}

.settings {
    align-items: center;
    background-color: $yellowBg;
    border-radius: 16px;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
    margin-top: 32px;
    margin-bottom: 37px;

    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    .subtitle {
        font-size: 14px;
        line-height: 20px;
    }
}

.small-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    box-shadow: 0px 4px 24px rgba(24, 41, 82, 0.06);
    border-radius: 8px;
    background-color: $white;
    margin-top: 24px;
    cursor: pointer;
    width: 100%;

    .title {
        color: $textMain;
        font-size: 14px;
        line-height: 20px;
    }
}

.today-notice {
    display: flex;
    align-items: center;
    background-color: $yellowBg;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin-bottom: 24px;
}

.today {
    position: absolute;
    bottom: 10px;
    left: 0;
    color: $accentMain;
    padding: 0;
    padding-left: 20px;
}

.noAppointmentsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    .noAppointmentDino {
        position: relative;
        text-align: center;
        .noAppointments {
            font-size: 1.25rem;
            color: $textGrey;
            margin-top: 20px;
            // text-align: center;
            margin-left: -20px;
        }
        .dinoBubble {
            position: absolute;
            width: 121.78px;
            height: 106.09px;
            top: -9px;
            left: -77px;
            svg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transform: scaleX(-1);
            }
            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                text-align: center;
            }
        }
        .consultationBtn {
            margin-left: -20px;
            margin-top: 48px;
        }
    }
}

.noAppointments {
    color: $textLight;
    font-size: 16px;
    line-height: 24px;
}

.filters {
    margin-bottom: 25px;
}

.status {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $textMain;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: $linesSeparator;
}

.created {
    color: $textLight;
    background-color: $grey;
}
.pending {
    color: $textMain;
    background-color: $yellowBg;
}
.confirmed {
    color: $textMain;
    background-color: $greenLight;
}
.completed {
    color: $textMain;
    background-color: $linesSeparator;
}
.cancelled {
    color: $textMain;
    background-color: $redBg;
}

.rightLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 32px;
}

.dino1 {
    padding-top: 40px;
    border-bottom: 1px solid #8593a3;

    .bubble {
        position: relative;
        display: flex;
        align-items: center;
        width: 165px;

        svg {
            position: absolute;
            top: -70px;
            right: -110px;
        }
    }

    > svg {
        transform: rotateY(180deg);
    }

    p {
        position: absolute;
        top: -25px;
        right: -107px;
        width: 100%;
        z-index: 1;
        text-align: center;
        padding: 0 20px;
        line-height: 18px;
    }
}

.hasMsgs {
    background-color: $redLight;
    outline: 11px solid $redLightTransparent;
    border-radius: 20px;
    overflow: visible;
    path {
        stroke: $red;
    }
    circle {
        fill: $red;
    }
    &.centered {
        margin: 43px auto 20px;
        display: block;
        cursor: pointer;
    }
}

.hasMsgDino {
    p {
        top: -30px;
        right: -111px;
    }
    svg path {
        fill: $yellowBg;
        stroke: none;
    }
}
