
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.button-settings {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $textMain;
}

.button-settings-horizontal {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 16px;
    color: $textMain;
    transition: background-color 0.3s;
    padding: 8px 16px;

    .bold {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    &:hover {
        background: $accentHighlight;
    }
}
