// disabled all click animations for all ant buttons/controls
.ant-wave {
    display: none !important;
}

// Buttons
html body {
    .ant-btn-primary {
        padding: 12px 24px;
        box-shadow: none;
        border: none !important;

        &:disabled {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $textLight;
            background-color: $grey;
        }

        &:not(:disabled) {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            background-color: var(--primary-btn-bg-color);

            &:hover {
                background-color: var(--primary-btn-hover-bg);
            }
        }
    }

    .ant-btn-default {
        padding: 12px 24px;
        box-shadow: none;
        border: none !important;

        &:disabled {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $textLight;
            background-color: $grey;
            opacity: 0.5;
        }

        &:not(:disabled) {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--default-btn-color);
            background-color: $grey;

            &:hover {
                background-color: var(--default-btn-hover-bg);
                color:  var(--default-btn-hover-color);
            }
        }
    }

    .ant-btn-lg {
        padding: 12px 32px !important;
        font-weight: 400 !important;
        font-size: 24px !important;
        line-height: 28px !important;
        height: 64px !important;
        border-radius: 16px !important;
        letter-spacing: -0.01em;
    }
}

.ant-message {
    z-index: $zNotification;

    .ant-message-notice {
        text-align: right;
    }
}
.ant-form-vertical .ant-form-item-label {
    padding-bottom: 0.25rem !important;
}
.ant-card {
    border: none;
    box-shadow: 0px 16px 32px rgba(23, 28, 72, 0.06);
    border-radius: 16px;
    .ant-card-head {
        border-radius: 16px 16px 0 0;
        padding: 20px 40px;
        @media screen and (max-width: 575px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .ant-card-body {
        border-radius: 0 0 16px 16px;
        padding: 24px 40px;
        @media screen and (max-width: 575px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
.ant-form-item {
    margin-bottom: 2rem;
}
.ant-form-item-explain-error {
    font-size: 0.75rem;
    text-align: left;
    margin-top: 4px;
}

// be careful
html body .ant-checkbox-wrapper {
    margin-bottom: 10px;

    .ant-checkbox {
        align-self: initial;

        & + span {
            position: relative;
            top: -3px;
            padding-inline-start: 16px;
        }

        .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border-radius: 4px;

            &::after {
                width: 7px;
                height: 13px;
                margin-top: -1px;
                margin-left: 1px;
            }
        }
    }
}

//modal
// .ant-modal-wrap {
//     display: flex;
//     align-items: center;
// }
.ant-modal {
    // top: 0;
    .ant-modal-content {
        padding: 0;
        border-radius: 8px;
    }

    .ant-modal-header {
        border-bottom: 1px solid $linesSeparator;
        padding: 16px 16px 16px 24px;
        .ant-modal-title {
            color: $textMain;
            font-size: 24px;
            line-height: 28px;
        }
    }

    .ant-modal-content {
        padding: 0;
        border-radius: 8px;
    }

    .ant-modal-header {
        border-bottom: 1px solid $linesSeparator;
        padding: 16px 16px 16px 24px;
        .ant-modal-title {
            color: $textMain;
            font-size: 24px;
            line-height: 28px;
            font-weight: 400;
        }
    }

    .ant-modal-body {
        padding: 24px;
    }

    .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        border-top: 1px solid $linesSeparator;
    }
}

.ant-select-dropdown {
    z-index: $zNotification + 1;
}

.ant-select-selector {
    .ant-select-selection-overflow {
        gap: 8px;
        .ant-select-selection-overflow-item {
            .ant-select-selection-item {
                padding: 6px 8px !important;
                margin: 0;
                height: 32px;
                background-color: $someBg;
                border-radius: 4px;
                border: none;
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }
}

.ant-select-multiple {
    .ant-select-selector {
        padding: 7px;
        min-height: 48px;
    }
    .ant-select-selection-item-remove svg {
        // TODO change the icon to a custom one
        font-size: 11px;
        path {
            stroke: $textGrey;
            stroke-width: 2px;
            fill: $textGrey;
        }
    }
}

html body ul.ant-dropdown-menu {
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 8px 12px rgba(23, 28, 72, 0.16);
    background-color: $white;
    padding: 8px 0;

    .ant-dropdown-menu-title-content {
        flex-grow: 1;

        &.green {
            margin-left: 22px;
            flex-grow: 999;

            svg {
                fill: $green;
            }
        }
    }

    li.ant-dropdown-menu-item {
        border-radius: 0;
        padding: 12px 16px;
        font-size: 1rem;
        color: $textMain;
        flex-direction: row-reverse;

        a,
        a:hover {
            color: $accentHover;
            text-decoration: none;
        }
    }
}

html body {
    .ant-input-disabled,
    .ant-input[disabled],
    .ant-select-disabled .ant-select-selector {
        background: $bgLightGrey !important;
        border: 1px solid $linesSeparator !important;
    }

    // red required asterisk
    .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: none;
    }

    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-picker-focused {
        box-shadow: none !important;
    }

    .ant-btn:not(:disabled):focus-visible {
        outline: none;
    }

    .ant-form-item-label > label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
    }
}

.confirmPopup {
    &_text {
        margin: 20px 0;
    }

    &_button {
        padding: initial !important;
    }
}
