
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 16px;
    :global {
        .ant-picker-dropdown {
            z-index: $zNotification;
        }

        .ant-checkbox-group {
            display: flex;
            justify-content: space-between;
        }
        div.ant-checkbox-group label:nth-last-child(-n + 2) {
            color: $redDark;
        }
    }
}

.label {
    color: $textGrey;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.tab {
    padding: 6px 16px;
    border-radius: 4px;
    background-color: $grey;
    color: $textGrey;
    width: 100%;
}
.active_tab {
    background-color: $accentVeryLight;
    color: $textMain;
}

.event-container {
    margin-top: 24px;
}

.eve-odd-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 8px;
}

.even-odd-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.week-container {
    margin-top: 24px;
}

.modalDescription {
    margin-bottom: 40px;
}

.descriptionRow {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.descriptionLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.descriptionText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.option-wrapper {
    margin-bottom: 16px;
}

.option-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.option-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.reason-wrapper {
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 4px;
    margin-top: 16px;
}

.topContent {
    margin-bottom: 40px;
}

.reason-wrapper {
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 4px;
}

.warningSection {
    background-color: $yellowBg;
    border-radius: 16px;
    padding: 16px 24px;
    margin-top: 16px;
}

.warning-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    display: flex;
    align-items: center;
}

.slotContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.day {
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
}

.disabled,
.selected-time,
.time {
    padding: 10px 12px;
    border-radius: 8px;
    background-color: $bgHighlightGrey;
    cursor: pointer;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.selected-time {
    background-color: $accentHover;
    color: $white;
}

.disabled {
    background-color: $linesSeparator;
    color: $textLight;
}
