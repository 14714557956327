
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.checkNotExistModal {
    :global {
        .ant-modal-content {
            .ant-modal-header,
            .ant-modal-footer {
                padding: 20px 40px;
            }
            .ant-modal-close {
                top: 24px;
                right: 24px;
            }
            .ant-modal-body {
                padding: 40px;
            }
            .ant-modal-footer {
                justify-content: end;
                padding-top: 24px;
                padding-bottom: 24px;
                .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
                    margin-inline-start: 14px;
                }
            }
        }
    }
    h3 {
        margin-top: 32px;
    }
    p {
        margin-top: 16px;
        color: $textMain;

        span {
            cursor: pointer;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration-line: underline;
            color: $accentMain;
        }
    }
    .phone-number {
        margin-top: 16px;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.32px;
        color: $textMain;
    }
    .menuShot {
        width: 402px;
        height: 103px;
    }
}
