
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
}

.content {
    padding-left: 312px;
    position: relative;
}

.profileTitle {
    margin-bottom: 0;
}

.infoValue {
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: $textMain;
    margin-bottom: 20px;
    &.nurse {
        margin-bottom: -4px;
    }
}

.info {
    svg {
        width: 27px;
        height: 27px;
    }
    .infoValue {
        margin-bottom: 0;
        font-size: 0.875rem;
        color: $textGrey;
    }
}

.nonAvatarBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background: $bgLightBlue;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
}
