
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $white;
    overflow: hidden;

    h6 {
        font-weight: 500;
        margin-bottom: 1rem;
        padding: 16px 16px 0 16px;
    }

    .chatIcon {
        width: 36px;
        height: 36px;
        display: block;
        margin: 14px auto;
    }

    .description {
        color: $textLight;
        text-align: center;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .startOfChat {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .description {
            flex-grow: 0;
        }
    }

    .chatBody {
        flex-grow: 1;
        display: flex;
        min-height: 230px; // todo just for test
        overflow: auto;
    }

    .chatFooter {
        padding: 16px;
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
    }
}

.messages {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding: 16px;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;

    .message {
        background-color: $greenLight;
        margin: 8px 9px;
        padding: 8px 16px;
        position: relative;
        border-radius: 4px;
        max-width: 70%;

        .text {
            font-size: 16px;
            line-height: 24px;
            color: $textMain;
            margin-bottom: 4px;
        }

        .dateBlock {
            display: flex;
            align-items: center;
            gap: 4px;

            .date {
                font-size: 12px;
                line-height: 16px;
                color: $textLight;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 0;
            height: 0;
            border: 9px solid transparent;
        }

        &:not(.mine) {
            &:after {
                left: 0;
                border: 9px solid transparent;
                border-right-color: $greenLight;
                border-left: 0;
                margin-top: -9px;
                margin-left: -9px;
            }
        }

        &.mine {
            background-color: $grey;
            align-self: flex-end;

            &:after {
                right: 0;
                border: 9px solid transparent;
                border-left-color: $grey;
                border-right: 0;
                margin-top: -9px;
                margin-right: -9px;
            }
        }
    }

    .description {
        align-self: center;
    }
}
