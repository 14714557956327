
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100%;
}

.border-container {
    box-shadow: 0 0 0 24px $grey;
    border-radius: 16px;
    margin: 112px 0;

    .content {
        padding: 40px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .title {
        font-size: 24px;
        line-height: 28px;
        color: $textMain;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .subtitle {
        color: $textGrey;
    }

    &.consultationBordered {
        margin-top: 64px;
        .title {
            margin-bottom: 36px;
        }
        .doctorBlock,
        .statusBlock {
            max-width: 320px;
            .title {
                font-size: 1rem;
                font-weight: 500;
            }
            .subtitle {
                line-height: 1rem;
            }
            .link {
                margin-bottom: 0;
            }
        }
        .flex4section {
            :global {
                .ant-space-item:nth-child(2),
                .ant-space-item:nth-child(4) {
                    flex-grow: 1;
                }
            }
        }
        .timeBlock {
            .date {
                font-weight: 500;
            }
            .time {
                font-size: 1.5rem;
            }
        }
        .bottomSection {
            margin-top: 40px;
            .title {
                margin-bottom: 24px;
            }
        }
    }
}

.dino-bottom {
    position: absolute;
    bottom: -50px;
    right: 50%;

    .bubble {
        position: absolute;
        max-width: 150px;
        top: 67px;
        right: -147px;

        .title {
            position: absolute;
            top: 32px;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #505862;
            text-align: center;
            transform: rotate(-10deg);
            z-index: 1;
        }

        svg {
            transform: scale(1, -1);
            path {
                fill: $bgLightGrey;
            }
        }
    }
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
    margin-bottom: 40px;
}

.proceduresTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $textMain;
    max-width: 80%;
}

.link {
    padding: 0;
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.divider {
    background-color: $border;
    height: 1px;
    width: 100%;
}

.big-divider {
    background-color: $grey;
    height: 24px;
    width: 100%;
}

.vertical-divider {
    box-sizing: border-box;
    background-color: $grey;
    min-height: 68px;
    width: 8px;
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    width: 200px;
}

.doc-container {
    .title {
        font-size: 20px;
        line-height: 24px;
    }
    .subtitle {
        color: $textGrey;
    }
}

.support {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-bottom: 256px;
}

.grayBlock {
    background-color: $grey;
    .dino5r {
        text-align: right;
        svg {
            transform: scaleX(-1);
        }
        .dino {
            position: relative;
            right: 8px;
            margin-bottom: 0;
        }
        .bubble {
            position: absolute;
            width: 150px;
            height: 107px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 6px;
            right: 365px;
            svg path {
                fill: $bgLightGrey;
            }
            p {
                position: absolute;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.2rem;
                color: $textMain;
                z-index: 1;
                text-align: center;
            }
        }
    }
}

.dino2_1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 64px;
    margin-top: 64px;
    .bubble {
        width: 118px;
        height: 96px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -8px;
        p {
            position: relative;
            z-index: 1;
            text-align: center;
            line-height: 1.125rem;
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
            transform: scaleX(-1);
            path {
                fill: transparent;
            }
        }
    }
}

.supportModal {
    :global {
        .ant-modal-content {
            .ant-modal-header,
            .ant-modal-footer {
                padding: 20px 40px;
            }
            .ant-modal-close {
                top: 24px;
                right: 24px;
            }
            .ant-modal-body {
                padding: 32px 40px;
                text-align: center;
            }
            .ant-modal-footer {
                padding-top: 24px;
                padding-bottom: 24px;
                .ant-btn {
                    width: 50%;
                }
                .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
                    margin-inline-start: 14px;
                }
            }
        }
    }
    p {
        color: $textMain;
    }
    .phone {
        font-size: 2rem;
        font-weight: 500;
        margin-top: 12px;
        line-height: 2rem;
    }
    .menuShot {
        width: 402px;
        height: 103px;
    }
}

.settings-icon-container {
    padding: 8px 24px 8px 16px;
    display: flex;
    align-items: center;
}

.setting-icon {
    cursor: pointer;
}

.addSnilsSection {
    display: flex;
    gap: 32px;
    background-color: $yellowBg;
    padding: 51px 64px 51px 0;
}