
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
@import 'ant.override';
@import 'mui.override';
@import 'phoneInput.override';
@import 'carousel.override';
@import 'scheduler.override';

// Hide arrows for input[type="number"]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $bgMain;

    p {
        margin: 0;
        font-size: 1rem;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2rem;
    letter-spacing: -0.01em;
    color: $textMain;
}

h1 {
    font-size: 32px;
    line-height: 40px;
}

h2 {
    font-size: 24px;
    line-height: 28px;
}

h3 {
    font-size: 20px;
    line-height: 24px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Global styles
video {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    display: block;
}

.kurento-participant-video {
    height: 100%;
}

.iconBtn {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    cursor: pointer;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.logo {
    height: auto;
    margin-bottom: 2.5rem;
}

.logo-big {
    width: 160px;
    height: 108px;
}

a {
    text-decoration: none;
    color: $accentMain;
    &:hover {
        color: lighten($accentMain, 30%);
    }
}

.topIndex {
    z-index: 20000;
}

.pointer {
    cursor: pointer;
}

svg.transparent {
    rect {
        fill: none;
    }
}

.gotoBtn {
    display: flex;
    align-items: center;
    padding: 4px !important;
    background: $grey;
    border-radius: 4px;
    width: 32px;
    height: 32px;

    svg {
        width: 24px;
        height: 24px;
    }
}

.backBtn {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $grey;
    border-radius: 8px;
    padding: 8px !important;

    svg {
        width: 24px;
        height: 24px;
    }
}

.bigBtn {
    padding: 12px 32px !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    height: 64px;
    letter-spacing: -0.01em;
    border-radius: 16px;
}

#cadesplugin_ovr {
    z-index: 99999;
}
