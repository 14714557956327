
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 40px;
    :global {
        .ant-picker-dropdown {
            z-index: $zNotification;
        }

        .ant-checkbox-group {
            display: flex;
            justify-content: space-between;
        }
        div.ant-checkbox-group label:nth-last-child(-n + 2) {
            color: $redDark;
        }
    }
}

.label {
    color: $textGrey;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.tab {
    padding: 6px 16px;
    border-radius: 4px;
    background-color: $grey;
    color: $textGrey;
    width: 100%;
}
.active_tab {
    background-color: $accentVeryLight;
    color: $textMain;
}

.event-container {
    margin-top: 24px;
}

.time {
    margin-top: 32px;
}

.eve-odd-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 8px;
}

.even-odd-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.week-container {
    margin-top: 24px;
}

.warning {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #cf2626;
}
