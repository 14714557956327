
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.border-container {
    box-shadow: 0 0 0 24px $grey;
    border-radius: 16px;
    margin: 112px 0;

    .content {
        padding: 40px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .title {
        font-size: 24px;
        line-height: 28px;
        color: $textMain;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .subtitle {
        color: $textGrey;
    }

    &.consultationBordered {
        margin-top: 64px;
        .title {
            margin-bottom: 36px;
        }
        .doctorBlock,
        .statusBlock {
            max-width: 320px;
            .title {
                font-size: 1rem;
                font-weight: 500;
            }
            .subtitle {
                line-height: 1rem;
            }
            .link {
                margin-bottom: 0;
            }
        }
        .flex4section {
            :global {
                .ant-space-item:nth-child(2),
                .ant-space-item:nth-child(4) {
                    flex-grow: 1;
                }
            }
        }
        .timeBlock {
            .date {
                font-weight: 500;
            }
            .time {
                font-size: 1.5rem;
            }
        }
        .bottomSection {
            margin-top: 40px;
            .title {
                margin-bottom: 24px;
            }
        }
    }

    .directly-payment-block {
        margin-top: 24px;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 24px;
        border-radius: 16px;
        background: var(--yellow-light, #fff0c8);

        .warning-icon {
            padding: 12px;
            border-radius: 40px;
            background: var(--yellow, #ffb876);

            svg {
                width: 24px;
                height: 24px;
            }
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: $textMain;
        }

        span {
            cursor: pointer;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration-line: underline;
            color: $accentMain;
        }
    }
}

.link {
    padding: 0;
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.linkBtn {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 12px;
    gap: 2px;
    background: $accentHighlight;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;
}

.divider {
    background-color: $border;
    height: 1px;
    width: 100%;
}

.dino-ready {
    position: absolute;
    top: -100%;
    right: 0;

    .bubble {
        position: absolute;
        max-width: 150px;
        transform: rotate(45deg);
        top: 26px;
        left: -163px;

        .title {
            position: absolute;
            top: 35px;
            left: 32px;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #505862;
            text-align: center;
            transform: rotate(-20deg);
            z-index: 1;
        }

        svg {
            transform: rotate(155deg);
            path {
                fill: $bgLightGrey;
            }
        }
    }
}

.consultationFinishedBlock {
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 56px 64px;
    background: $grey;
    border-radius: 32px;
    margin: 64px 0 112px;

    h2 {
        margin-bottom: 8px;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    .waitDoctorResultText {
        margin-left: 29px;
        max-width: 528px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .dino {
        position: absolute;
        right: 21px;
        bottom: -35px;

        svg {
            width: 416px;
            height: 217px;
            transform: rotateY(180deg);
        }

        .bubble {
            position: absolute;
            top: -22px;
            right: 333px;

            .title {
                position: absolute;
                top: 32px;
                left: 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #1b1d21;
                text-align: center;
                z-index: 1;
            }

            svg {
                width: 121px;
                height: 105px;
                transform: scaleX(-1.3);
                path {
                    fill: $bgLightGrey;
                }
            }
        }
    }
}

.cancelledInfo {
    display: flex;
    align-items: center;
    gap: 16px;
}
