
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 40%;
    max-width: 400px;
    min-width: 240px;
    margin: auto;
    text-align: center;
}

.bottomTxt {
    margin-top: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}
