
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100vh;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.appointmentsNavigation {
    margin-top: 24px;
    margin-bottom: 24px;

    a {
        padding: 12px 16px;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }

    a.active {
        border-bottom: 2px solid #6171ff;
        color: $textMain;
    }

    span {
        display: inline-block;
        margin-left: 8px;
        width: 24px;
        height: 24px;

        text-align: center;
        vertical-align: middle;

        font-weight: 700;
        font-size: 12px;
        line-height: 24px;
        color: $white;

        background: $red;
        border-radius: 29px;
    }
}

.filterBlock {
    margin-top: 32px;
}

.bottomSection {
    margin-bottom: 12px !important;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.nonActive {
    color: $red;
}

.employeeRow tr:hover td {
    background: $bgLightGrey;
    border-bottom: 1px solid $bgLightBlue;
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
    cursor: pointer;
}

.filterBlock {
    :global {
        .ant-checkbox-wrapper {
            margin: 0;

            /* Body/16 Reg */
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $textMain;
        }
    }
}

.employeeRow tr td {
    /* Body/14 Reg */
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.employeeRow {
    :global {
        .ant-pagination {
            background: white;
            justify-content: start;
            align-items: center;
            margin: 0 !important;
            padding: 16px;

            li:first-child {
                margin-right: auto;
            }

            li:last-child {
                margin-left: auto;
            }

            .ant-pagination-total-text {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: $textMain;
            }

            .ant-pagination-options {
                margin: 0;
            }

            .ant-pagination-options .ant-select-selector {
                /* Body/14 Bold */
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                color: $accentMain;
                border: none;
            }
        }
    }
}

.statusSuccess {
    padding: 4px 8px;
    background: $yellowBg;
    border-radius: 8px;

    svg {
        path {
            stroke: $yellow;
        }
        circle {
            fill: $yellow;
        }
    }
}

.statusError {
    padding: 4px 8px;
    background: $redBg;
    border-radius: 8px;

    svg {
        path {
            stroke: $red;
        }
        circle {
            fill: $red;
        }
    }
}

.statusInfo {
    padding: 4px 8px;
    background: $grey;
    border-radius: 8px;

    svg {
        path {
            stroke: $border;
        }
        circle {
            stroke: $border;
        }
    }
}

.eventField {
    display: flex;

    div:first-child {
        padding: 8px;
        margin-right: 8px;
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 40px;

        svg {
            width: 24px;
            height: 24px;
        }
    }
    p {
        /* Body/14 Reg */
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $textMain;
    }
}

.secondaryField {
    /* Body/14 Reg */
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    color: $textGrey !important;
}

.appointmentButton {
    width: 32px;
    height: 32px;
    padding: 4px;

    svg {
        vertical-align: baseline;
    }
}
