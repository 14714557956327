
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.upload-container {
    background-color: $bgLightGrey;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 24px 16px 16px;
}

.mainContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
    color: $textMain;
    font-size: 14px;
    line-height: 16px;

    .title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
    }

    .error-title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
    }

    .subtitle {
        color: $textGrey;
    }

    .error-subtitle {
        color: $redDark;
    }

    .uploaded-title {
        color: $textGrey;
    }
}

.upload-button {
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;
}

.spinner svg {
    width: 24px;
    height: 24px;
}
