
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 40%;
    max-width: 400px;
    min-width: 240px;
    margin: auto;
    text-align: center;
}

.contentLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.check {
    margin-bottom: 28px;
}
