
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding-top: 48px;
    margin: 0 auto;
    max-width: 1200px;
}

.start-wrapper {
    padding-top: 120px;
    margin: 0 auto;
    max-width: 800px;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.diagnosis-title {
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
    margin-bottom: 4px;
}

.unknown {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.appointment-type {
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 16px;
    background-color: $white;
    padding: 26px;
    margin-bottom: 16px;
    cursor: pointer;

    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    .subtitle {
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
    }
}

.consultation-step {
    margin-top: 40px;
}

.active,
.sign-step {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    &.bw {
        filter: grayscale(1);
        opacity: 0.5;
        .title svg {
            display: none;
        }
    }
    &.hover {
        cursor: pointer;
    }

    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textMain;
        margin-left: 20px;
        div {
            font-weight: 400;
            color: $textGrey;
        }
        svg {
            margin-left: 11px;
        }
    }
}

.active {
    background: #edf3fe;
    .title {
        color: $textMain;
        svg {
            display: none;
        }
    }
}

.dino2_1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 32px;
    .bubble {
        width: 118px;
        height: 96px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -8px;
        p {
            position: relative;
            z-index: 1;
            text-align: center;
            line-height: 1.125rem;
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
            transform: scaleX(-1);
            path {
                fill: transparent;
            }
        }
    }
}

.modal {
    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textGrey;
    }
}

.transparentIcon {
    rect {
        fill: transparent;
    }
}
