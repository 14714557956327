
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    min-width: 500px;
    min-height: 500px;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: black;
}

.square {
    position: absolute;
    background-color: red;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.cross {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .crossX {
        background-color: green;
    }

    .crossY {
        background-color: green;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.circle {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
