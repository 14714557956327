
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
@font-face {
    font-family: 'MyFont';
    src: url('../../styles/fonts/Sivtsev-Eye-Chart.otf') format('opentype');
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'MyFont', sans-serif;

    .letter {
    }
}
