
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.pageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $bgMain;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    width: 592px;

    @media screen and (max-width: 575px) {
        min-width: 0;
        width: 100%;
        box-shadow: none;
        border-radius: 0;
    }

    &.hidden {
        position: absolute;
        visibility: hidden;
        z-index: -100;
    }

    &.visible {
        visibility: visible;
        z-index: 1;
    }
}

.step {
    @media screen and (max-width: 575px) {
        box-shadow: none;
        border-radius: 0;
    }
}

.header {
    display: flex;
    align-items: center;

    h4 {
        margin: 0;
        white-space: break-spaces;
    }
}

.stepNumber {
    display: block;
    padding: 8px 16px;
    background-color: $blueVerifyLight;
    border-radius: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: $textGrey;
}

.remark {
    font-size: 0.8rem;
    color: $textGrey;
    margin-bottom: 40px;
}

.description {
    color: $textMain;
    margin-bottom: 20px;
}

.genderCel {
    padding-top: 6px;
}

.genderLabel {
    padding-top: 24px;
}

.gender {
    :global {
        .ant-checkbox-group-item {
            padding-right: 16px;
            .ant-checkbox + span {
                padding-inline-start: 8px;
                padding-inline-end: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.smaller {
    font-size: 0.875rem;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.smallCheckBox {
    margin-bottom: 0 !important;
    :global {
        .ant-checkbox-inner {
            width: 16px !important;
            height: 16px !important;
            &::after {
                height: 9px !important;
                width: 6px !important;
                margin-top: 0 !important;
                margin-left: 0 !important;
            }
        }
        .ant-checkbox + span {
            position: relative;
            top: 0 !important;
            padding-inline-start: 8px !important;
            padding-right: 0;
        }
    }
}
