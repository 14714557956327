
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: $zVideoConference;
    // background-color: $bgMain;
    background-color: $bgLightBlue;
    background-image: url('/../public/img/procedure_bg.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 40%;
}

.loaderWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.conference {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.room {
    flex-grow: 1;
    position: relative;

    &.hostNotHere {
        background-color: $grey;
        background-image: url('/../public/img/procedure_bg.svg');
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: bottom;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .dino1 {
            display: flex;
            align-items: center;

            .bubble {
                position: relative;
                top: -140px;
                max-width: 160px;
                display: flex;
                align-items: center;

                svg {
                    position: absolute;
                    top: 50%;
                    margin-top: -62px;
                    left: 0;
                    path {
                        fill: $bgLightGrey;
                    }
                }
            }

            p {
                position: relative;
                width: 100%;
                z-index: 1;
                text-align: center;
                padding: 0 20px;
                line-height: 18px;
            }

            &.step4 .bubble {
                max-width: 216px;

                svg {
                    margin-top: -84px;
                }
            }
        }
    }

    &.procedureNotStarted {
        background-color: $grey;

        .dino2_3 {
            display: flex;
            align-items: center;
            max-height: 100%;
            position: absolute;
            bottom: 0;
            overflow: hidden;

            .dino2_3img {
                width: calc((100vw - 360px) * 0.75);
                height: 100vh;
                max-width: 808px;
                align-self: flex-end;
                position: relative;
                svg {
                    width: 100%;
                    min-width: 160px;
                    height: 75%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    min-width: 560px;
                }
            }

            .bubble {
                position: relative;
                left: -5vw;
                top: 16.5vh;
                width: 165px;

                svg {
                    position: absolute;
                    top: 50%;
                    margin-top: -39%;
                    left: 0;
                }
            }

            p {
                position: relative;
                width: 100%;
                z-index: 1;
                text-align: center;
                padding: 0 20px;
                line-height: 18px;
            }
        }
    }

    &.needChangeGlasses {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bgLightBlueBrighter;

        .putOutGlassesWrapper {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $bgLightBlueBrighter;
            z-index: $zPauseOverlay;
        }

        .putOutGlasses {
            width: 100%;
            max-width: 472px;
            border: 24px solid $bgHighlightGrey;
            border-radius: 34px;
            text-align: center;
            background-color: $white;
            padding: 40px 49px 46px 49px;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2;

            .h2 {
                font-weight: 400;
            }

            .bigBtn {
                font-weight: 400;
                font-size: 150%;
                height: 64px;
            }
        }

        .dino5 {
            position: absolute;
            top: 37%;
            left: -85px;
            transform: rotate(-20deg) scale(1.3);
        }
    }

    .instruction {
        font-weight: 400;
    }

    .pause_overlay {
        position: absolute;
        backdrop-filter: blur(5px);
        background-color: rgba($color: #000000, $alpha: 0.5);
        width: 100%;
        height: 100%;
        z-index: $zPauseOverlay;
        display: flex;
        align-items: center;
        justify-content: center;

        .pause_text {
            display: block;
            background-color: $white;
            font-size: 20px;
            text-align: center;
            padding: 40px 60px;
            border-radius: 8px;
        }
    }
}

.diagonalChangedOverlay {
    position: absolute;
    backdrop-filter: blur(5px);
    background-color: rgba($color: #000000, $alpha: 0.5);
    width: 100%;
    height: 100%;
    z-index: $zPauseOverlay;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        width: 356px;
        padding: 40px 36px;
        border-radius: 8px;
        text-align: center;
        background-color: $white;
    }

    span {
        margin-top: 24px;
        display: block;
        font-size: 24px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: -0.32px;
    }
}

.colorController {
    position: absolute;
    z-index: 1;
    top: 24px;
    right: 24px;
    background-color: white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 16px;
    padding: 24px;
    width: 320px;
}

.endProcedure {
    margin-bottom: -104px;
    margin-top: 56px;
    min-width: 320px;
}

.participants {
    width: 100%;
}

.hostSide {
    display: flex;
    flex-direction: column;
    flex: 0 0 350px;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    background-color: white;
    z-index: 10;
    transition: filter 0.3s;

    &.procedureActive {
        filter: brightness(0.2);

        &:hover {
            filter: none;
        }
    }

    .aside_host {
        .nurseIsNotStreaming {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .doctorName {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px 16px 24px 16px;
            border-bottom: 1px solid $border;
            color: $textMain;
            span {
                font-size: 0.875rem;
            }
        }
        .nurseAvatar {
            width: 184px;
            height: 184px;
            object-fit: cover;
            border-radius: 92px;
            display: block;
            margin: 0 auto;
        }
    }

    .aside_content {
        padding: 12px;
        border: 1px solid $bgLightBlue;

        h5 {
            margin-bottom: 0;
        }
    }
    .nurseOnTheWay {
        height: 200px;
        background-color: $grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $textLight;

        &.bye {
            flex-direction: row;
            justify-content: flex-end;

            .bubble {
                position: relative;
                max-width: 165px;
                top: -41px;
                left: -12px;
                svg {
                    position: absolute;
                    top: 50%;
                    margin-top: -39%;
                    left: 0;
                    transform: scale(-1, 1);
                    path {
                        fill: $bgLightGrey;
                    }
                }
            }

            p {
                position: relative;
                width: 100%;
                z-index: 1;
                text-align: center;
                padding: 0 20px;
                line-height: 18px;
            }
        }
    }

    .chat {
        background-color: $bgMain;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 auto;
        overflow: auto;

        h6 {
            font-weight: 500;
            margin-bottom: 1rem;
            padding: 16px 16px 0 16px;
        }

        .chatIcon {
            width: 36px;
            height: 36px;
            display: block;
            margin: 14px auto;
        }

        .description {
            color: $textLight;
            text-align: center;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .startOfChat {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .description {
                flex-grow: 0;
            }
        }

        .chatBody {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
        }

        .chatFooter {
            padding: 16px;
            box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
        }
    }
}

.formBlock {
    width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.indicator {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: $red;
    box-shadow: 0px 0px 7px 4px rgba(243, 86, 37, 0.2);
}

.closeBtn {
    width: 40px !important;
    height: 40px;
    padding: 0 !important;
    &,
    &:hover {
        color: $red !important;
    }
}

.closeWhite {
    margin-top: -2px;
}

.fullScreenVideo {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: gray;
    justify-content: center;
    align-items: center;

    :global {
        .kurento-participant-video {
            max-height: 100%;
        }
    }

    .preview {
        position: absolute;
        top: 0;
        left: 0;
        max-height: 210px;
        max-width: 280px;

        :global {
            .kurento-participant-video {
                height: 100%;
            }
        }
    }
}
