
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.accountTitle {
    h4 {
        margin-bottom: 0;
    }
}

.cancelButton {
    margin-right: 16px;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.smallCheckBox {
    :global {
        .ant-checkbox-inner {
            width: 16px !important;
            height: 16px !important;
            &::after {
                height: 9px !important;
                width: 6px !important;
                margin-top: 0 !important;
                margin-left: 0 !important;
            }
        }
        .ant-checkbox + span {
            position: relative;
            top: 0 !important;
            padding-inline-start: 8px !important;
            padding-right: 0;
        }
    }
}

.checkboxItem {
    margin-bottom: 0 !important;

    :global {
        .ant-form-item-explain-error {
            display: none;
        }
        .ant-checkbox-input[aria-invalid='true'] + .ant-checkbox-inner {
            border-color: $red;
        }
    }
}
