
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    :global {
        .ant-collapse {
            .ant-collapse-item {
                .ant-collapse-header {
                    padding: 24px 32px;
                    align-items: center;

                    .ant-collapse-header-text {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: $textMain;
                    }
                }
                .ant-collapse-content-box {
                    padding: 18px 32px;
                }
            }
        }

        .ant-collapse-content-box {
            padding: 32px;
        }
    }
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
}

.active {
    transform: rotate(180deg);
    transition: all 0.4s;
}

.nonActive {
    transform: rotate(0);
    transition: all 0.4s;
}

.collapse {
    background: $white;

    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 16px;
}

.panel {
    border-bottom: 1px solid $linesSeparator;

    .paragraph {
        margin-bottom: 20px;
    }

    .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textLight;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }
}

.document-container {
    display: flex;
    align-items: center;
    background-color: $bgLightGrey;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 8px;
}

.document-name {
    color: $textMain;
    font-size: 14px;
    line-height: 16px;
    margin-left: 13px;
}

.status {
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.success {
    background-color: $greenLight;
}

.default {
    background-color: $grey;
}

.procedure-container {
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 16px;
    padding: 16px 32px;
    margin-bottom: 24px;

    .title {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $textMain;
        margin-bottom: 8px;
    }

    .procedure-card {
        padding: 24px 0;
    }
    .filtered {
        filter: grayscale(100%);

        .procedure-card-description {
            color: $textLight;
        }
    }

    .procedure-card-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }
}

.chosen {
    background-color: $blueVerifyLight;
}

.previewMask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: $zActiveProcedure;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
        width: 50%;
        height: 100%;
        min-width: 720px;
        display: block;
    }
    .closePreviewBtn {
        position: absolute;
        top: 12px;
        right: 15px;
        cursor: pointer;
    }
}

.editButton {
    cursor: pointer;
}

.dropdownDots {
    height: 100%;
    padding: 0;

    &:hover {
        svg {
            path {
                stroke: $accentHover;
            }
        }
    }
}

.dropdownOption {
    padding: 11px 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.listWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.uploadLabel {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $textGrey;
    margin-bottom: 16px;
}

.settingsBtn {
    width: 40px;
    height: 40px;
    padding: 8px !important;
}

.paymentTitle {
    color: #1B1D21;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.paymentDate {
    color: #8593A3;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}

.paymentMethod {
    color: #505862;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.waitCheck {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #FF8A43;
}

.seeCheck {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #235ABE;
}