
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 40px;
    :global {
        .ant-picker-dropdown {
            z-index: $zNotification;
        }

        .ant-checkbox-group {
            display: flex;
            justify-content: space-between;
        }
    }
}

.label {
    color: $textGrey;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.event-container {
    margin-top: 24px;
}

.time {
    margin-top: 32px;
}
