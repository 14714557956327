
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100vh;

    :global {
        .dx-scheduler-header-panel-cell {
            cursor: pointer;
        }
        .dx-scheduler-header-panel-empty-cell {
            width: 60px !important;
        }
        .dx-scheduler-time-panel {
            width: 60px;
        }
        .ant-btn-text:hover {
            background-color: transparent;
        }
    }
}

.disabled {
    opacity: 0.5;
}

.title {
    margin: 0;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.legend {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $accentMain;
}

.filterBlock {
    margin-bottom: 25px;
}

.tab {
    padding: 6px 16px;
    border-radius: 4px;
    background-color: $grey;
    color: $textGrey;
}
.active_tab {
    background-color: $accentVeryLight;
    color: $textMain;
}

.emptySchedule {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emptyScheduleFull {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    color: $textGrey;
}

.budge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
    background-color: $white;
    color: $textMain;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slotWeek {
    display: flex;
}
.weekAppointmentText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.monthAppointmentText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $white;
}

.slotMonth,
.slot {
    color: $textMain;
    width: 163px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    :global {
        .dx-scheduler-appointment-collector.dx-button,
        .dx-scheduler-appointment-collector.dx-button.dx-state-active,
        .dx-scheduler-appointment-collector.dx-button.dx-state-focused,
        .dx-scheduler-appointment-collector.dx-button.dx-state-hover {
            display: none !important;
        }
        .dx-scheduler-appointment-collector.dx-button.dx-button-default,
        .dx-scheduler-appointment-collector {
            display: none !important;
        }
    }
    &.diagnostics {
        background: #235abe;
        color: $white;

        &.free {
            background: #caddff;
            border: 1px dashed #235abe;
            color: $textMain;
        }
    }

    &.consultation {
        background: #12b9e2;
        color: $white;

        &.free {
            background: #d2f6ff;
            border: 1px dashed #12b9e2;
            color: $textMain;
        }
    }

    &.any {
        background: white;

        &.free {
            background: #eff2f5;
            border: 1px dashed #c3ccd6;
        }
    }

    &.ANY {
        background-color: $white;
    }
    &.DIAGNOSTIC {
        background: #235abe;
        color: $white;
    }
    &.DIAGNOSTICS {
        background: #235abe;
        color: $white;
    }
    &.MYOPIA_TREATMENT_PACKAGE {
        background: #cf2626;
        color: $white;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE {
        background: #ff8a43;
    }
    &.STRABISMUS_TREATMENT_PACKAGE {
        background: #62c852;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE {
        background: #00bed8;
    }
    &.MYOPIA_STRABISMUS_TREATMENT_PACKAGE {
        background: #9300d8;
        color: $white;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE {
        background: #d80081;
        color: $white;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE {
        background: #769912;
        color: $white;
    }

    &.ANY-dashed {
        background: #eff2f5;
        border: 1px dashed #c3ccd6;
    }
    &.DIAGNOSTIC-dashed {
        background: #caddff;
        border: 1px dashed #235abe;
    }
    &.DIAGNOSTICS-dashed {
        background: #caddff;
        border: 1px dashed #235abe;
    }
    &.MYOPIA_TREATMENT_PACKAGE-dashed {
        background: #ffdfdf;
        border: 1px dashed #cf2626;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE-dashed {
        background: #fff0c8;
        border: 1px dashed #ff8a43;
    }
    &.STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #cbfdc3;
        border: 1px dashed #62c852;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE-dashed {
        background: #c7f8ff;
        border: 1px dashed #00bed8;
    }
    &.MYOPIA_STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #f2d6ff;
        border: 1px dashed #9300d8;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #ffc8e9;
        border: 1px dashed #d80081;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #edffb9;
        border: 1px dashed #769912;
    }
}

.slotMonth {
    width: 120px;
    border-radius: 8px;
    :global {
        .dx-scheduler-appointment-collector.dx-button.dx-button-default,
        .dx-scheduler-appointment-collector {
            display: block;
            border-radius: 8px;
            width: 120px !important;
        }
    }
}

.slot-color {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    margin-right: 16px;
    &.any {
        background: white;
    }
    &.any-dashed {
        background: #eff2f5;
        border: 1px dashed #c3ccd6;
    }

    &.diagnostics {
        background: #235abe;
    }
    &.diagnostics-dashed {
        background: #caddff;
        border: 1px dashed #235abe;
    }

    &.consultation {
        background: #12b9e2;
    }
    &.consultation-dashed {
        background: #d2f6ff;
        border: 1px dashed #12b9e2;
    }

    &.FREE {
        background-color: $white;
    }
    &.DIAGNOSTIC {
        background: #235abe;
    }
    &.DIAGNOSTICS {
        background: #235abe;
    }
    &.MYOPIA_TREATMENT_PACKAGE {
        background: #cf2626;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE {
        background: #ff8a43;
    }
    &.STRABISMUS_TREATMENT_PACKAGE {
        background: #62c852;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE {
        background: #00bed8;
    }
    &.MYOPIA_STRABISMUS_TREATMENT_PACKAGE {
        background: #9300d8;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE {
        background: #d80081;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE {
        background: #769912;
    }

    &.FREE-dashed {
        background: #eff2f5;
        border: 1px dashed #c3ccd6;
    }
    &.DIAGNOSTIC-dashed {
        background: #caddff;
        border: 1px dashed #235abe;
    }
    &.DIAGNOSTICS-dashed {
        background: #caddff;
        border: 1px dashed #235abe;
    }
    &.MYOPIA_TREATMENT_PACKAGE-dashed {
        background: #ffdfdf;
        border: 1px dashed #cf2626;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE-dashed {
        background: #fff0c8;
        border: 1px dashed #ff8a43;
    }
    &.STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #cbfdc3;
        border: 1px dashed #62c852;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE-dashed {
        background: #c7f8ff;
        border: 1px dashed #00bed8;
    }
    &.MYOPIA_STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #f2d6ff;
        border: 1px dashed #9300d8;
    }
    &.HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #ffc8e9;
        border: 1px dashed #d80081;
    }
    &.MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE-dashed {
        background: #edffb9;
        border: 1px dashed #769912;
    }
}

.headerDropdown {
    position: absolute;
    box-shadow: 0px 8px 12px rgba(23, 28, 72, 0.16);
    border-radius: 0px 0px 8px 8px;
    background-color: $white;
    width: 240px;
    z-index: $zScheduleTooltip;
    overflow: hidden;
}

.headerDropdown-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    color: $textMain;
    cursor: pointer;
}

.headerDropdown-item:hover {
    background-color: $accentHighlight;
}

.tooltip {
    border-radius: 16px;
    padding: 16px;
    width: 420px;
    // height: 188px;
    position: absolute;
    background-color: white;
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(23, 28, 72, 0.2);
    border-radius: 16px;
    z-index: $zScheduleTooltip;
    // top: 50%;
    // bottom: 50%;

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        :global {
            .ant-btn {
                height: auto;
            }
        }
    }

    .action-icon {
        cursor: pointer;
    }

    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        margin-bottom: 8px;
        display: flex;
        gap: 8px;
    }

    .date {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }
}

.legendContent {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 19px;

    .legendRow {
        display: flex;
        align-items: center;
    }
}

.dropdownMenu {
    background: $white;
    box-shadow: 0px 8px 12px rgba(23, 28, 72, 0.16);
    border-radius: 8px;
    overflow: hidden;
    .empty {
        span {
            opacity: 0.5;
        }
        cursor: default !important;
    }
    :global {
        .ant-dropdown-menu {
            border-radius: 8px;
            box-shadow: none;
            padding: 0;
            .ant-dropdown-menu-item {
                a,
                a:hover {
                    color: $textMain;
                    &.blue {
                        color: $accentHover;
                    }
                }
                padding: 12px 16px;
                border-radius: 0;
            }
        }
    }
}

.warning {
    display: flex;
    align-items: center;
    background: #ffdfdf;
    border-radius: 8px;
    padding: 8px;
    margin-top: 8px;
    .warningText {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $textMain;
        margin-left: 8px;
    }
}

.tooltip-header {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #235abe;

    &.text {
        color: $textMain;
    }
}

.patientInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.warningText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.searchInput {
    position: relative;
    .searchIco {
        position: absolute;
        top: 15px;
        left: 22px;
        z-index: 10;
    }
    .autocompleteCtrl {
        width: 100%;
        :global {
            input,
            .ant-select-selection-placeholder {
                padding-left: 24px !important;
            }
        }
    }
}

.calendarElement{
    max-width: 300px;
}

.allPractitionersCalendar {
    :global {
        .dx-scheduler-header {
            display: none;
        }
    }
}