.slick-track {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
}

.slick-slide {
    margin: 0;
}

.slick-next,
.slick-prev {
    z-index: $zNotification;
    top: 46%;
}

.slick-next {
    right: -4px;
}

.slick-prev::before,
.slick-next::before {
    color: $textGrey;
    font-size: 48px;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    display: none;
}
