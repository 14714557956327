.groupLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    margin: 32px 0 16px;
}

.groupSubLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin: 0 0 8px;
}

.formGroup {
    margin-bottom: 16px;

    :global {
        .ant-form-item-label > label,
        .ant-form-text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $textGrey;
        }

        .ant-form-text {
            padding-left: 16px;
        }

        .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
        }
    }

    .formLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        margin-bottom: 4px;
    }
}

// 2xAuto
.formGrid22 {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 16px;
    align-items: center;

    > label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
    }

    :global {
        .ant-form-item {
            margin-bottom: 0;
        }
    }
}

.glassesGrid {
    display: grid;
    grid-template-columns: repeat(7, max-content);
    gap: 8px 16px;
    align-items: center;

    .groupLabel {
        margin: 0;
        min-width: 40px;
    }

    :global {
        .ant-form-text {
            padding: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $textGrey;
        }
    }
}

.checkboxGroup {
    margin-bottom: 16px;

    :global {
        .ant-checkbox-wrapper {
            margin: 0;
        }

        .ant-form-item {
            margin: 0;
            margin-bottom: 8px;

            .ant-form-item-control-input {
                min-height: 0;
            }
        }

        .ant-checkbox + span {
            top: -4px;
        }
    }
}

.checkboxGroupList {
    :global {
        .ant-checkbox-group {
            display: flex;
            flex-direction: column;

            .ant-checkbox-wrapper {
                margin-inline-start: 0;
            }
        }
    }
}

.radioGroup {
    display: flex;
    gap: 8px;
    color: $textMain;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    .radio {
        display: flex;
        justify-content: center;
        padding: 6px 16px;
        border-radius: 4px;
        background-color: $grey;
        cursor: pointer;
        min-width: 80px;

        &.selected {
            background-color: $accentVeryLight;
        }
    }

    &.disabled {
        opacity: 0.6;

        .radio {
            cursor: not-allowed;
            user-select: none;
        }
    }
}

.diagnosticResults {
    padding: 24px;
    background: $blueVerifyLight;
    border-radius: 8px;

    .groupLabel {
        margin-top: 0;
    }

    :global {
        .ant-form-item {
            margin: 0;
            margin-bottom: 8px;

            .ant-row {
                align-items: center;
                gap: 16px;
            }

            .ant-form-item-label {
                text-align: left;
                width: 115px;
            }

            .ant-form-item-label > label,
            .ant-form-text {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $textGrey;
                white-space: initial;
                height: initial;

                &::after {
                    content: none;
                }
            }
        }
    }

    .formGrid22 {
        :global {
            .ant-form-item {
                margin-bottom: 0;
            }
        }

        > label {
            width: 115px;
        }
    }

    .selector {
        margin-bottom: 32px;
    }
}

.groupError {
    font-size: 0.75rem;
    color: $red;
    margin: 0 0 16px;
}
