
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.participant {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    color: white;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    transition: border-color 0.3s;

    &.host {
        display: flex;
        border-radius: 0;
    }

    &:hover {
        .participant_subtitle,
        .patient_actions {
            display: flex;
        }
    }
}

.video_wrapper {
    position: relative;
    max-width: 100%;
    max-height: 220px;
    background-color: gray;
    overflow: hidden;
    :global {
        .kurento-participant-video {
            max-height: 220px;
        }
    }
}

.video_wrapper_fullscreen {
    position: relative;
    max-width: 100%;
    max-height: 100vh;
    background-color: gray;
}

.videoDisabled {
    &:before {
        content: '';
        background: gray;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.videoDisabled {
    &:before {
        content: '';
        background: gray;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.patient_actions {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 999;
    display: none;

    .action_btn {
        padding: 8px 0;
        background: $grey;
        border-radius: 8px;
        min-height: 40px;
        min-width: 40px;

        &.close_btn {
            color: $red;
        }
    }
}

.toolbar {
    padding: 8px 16px 16px;
    background: $grey;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 0 0 8px 8px;
    transition: background 0.3s;
}

.newMessage {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 999;

    .action_btn {
        padding: 8px 0;
        background: $grey;
        border-radius: 8px;
        min-height: 40px;
        min-width: 40px;

        &.close_btn {
            color: $red;
        }

        svg {
            path {
                stroke: $textGrey;
            }
            circle {
                fill: $textGrey;
            }
        }
    }
}

.procedures_state {
    display: flex;
    align-items: center;
    gap: 16px;

    .indicators {
        display: flex;
        align-items: center;
        gap: 4px;

        .value {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $textMain;
        }
    }
}

.procedures_list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;

    $itemsInRow: 4;
    $gap: 4px;
    gap: $gap;

    .procedure_item_container {
        flex: 0 0 calc(100% / $itemsInRow - $gap * ($itemsInRow - 1) / $itemsInRow); // gap compensation
        min-height: 24px;
    }
}

.video_actions {
    position: absolute;
    bottom: 8px;
    left: 16px;
    right: 16px;
    display: flex;
    justify-content: space-between;
    z-index: $zConferenceButtons;
}

.action_buttons {
    display: flex;
    gap: 8px;
    align-items: flex-end;
}

.participant_info {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 88px);
}

.participant_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.participant_subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
}

.participant_time {
    position: absolute;
    top: 8px;
    right: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.procedurePreview {
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: 50%;
    z-index: $zScreenSharingVideo;
    img {
        max-width: 100%;
    }
}

.participant_screenSharingFullScreen {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 100%;
    z-index: $zScreenSharingVideoFullScreen;
}

// ====================================================
// Selection types
// ====================================================
// todo change hardcoded colors to CSS variables

.participant {
    --selectionBorderColor: transparent;
    --selectionBackgroundColor: transparent;
}

.selection {
    transition: box-shadow 0.3s;
}

// Base class
.selection:not(.selection_done):not(.selection_select) {
    // todo maybe need to delete 'infinite' due to the performance reasons
    animation: selection-border-flash 2s infinite;

    .toolbar {
        animation: selection-background-flash 2s infinite;
        border-radius: 0;
    }
}
// it's without animation
.selection_done {
    box-shadow: 0 0 0 6px #62c852;

    .toolbar {
        background-color: #a6ee9a;
    }
}

.selection_select {
    box-shadow: 0 0 0 6px #235abe;
}

.selection_success {
    --selectionBorderColor: #62c852;
    --selectionBackgroundColor: #a6ee9a;
}

.selection_warning {
    --selectionBorderColor: #ffb876;
    --selectionBackgroundColor: #ffd67d;
}

.selection_failed {
    --selectionBorderColor: #f64d4d;
    --selectionBackgroundColor: #ffa396;
}

.selection_call {
    --selectionBorderColor: #4c83e5;
    --selectionBackgroundColor: #adc6f3;
}

.selection_chat {
    --selectionBorderColor: #bf3efc;
    --selectionBackgroundColor: #da8aff;
}

@keyframes selection-border-flash {
    0% {
        box-shadow: 0 0 0 6px var(--selectionBorderColor);
    }
    50% {
        box-shadow: 0 0 0 6px transparent;
    }
    100% {
        box-shadow: 0 0 0 6px var(--selectionBorderColor);
    }
}

@keyframes selection-background-flash {
    0% {
        background-color: var(--selectionBackgroundColor);
    }
    50% {
        background-color: transparent;
    }
    100% {
        background-color: var(--selectionBackgroundColor);
    }
}

.compact {
    height: 152px;
    width: 204px;

    .participant_info {
        max-width: 100%;
    }
}
