
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.blockWrapper {
    padding: 8px;
    .formButton {
        height: 40px;
        padding: 0 16px;
    }
    .backBtn {
        padding: 0;
        font-weight: 500;
        margin-bottom: 16px;

        svg {
            margin-right: 8px;
            transform: rotate(180deg);
            border-radius: 4px;
            background-color: $bgHighlightGrey;
            cursor: pointer;
            path {
                stroke: $textGrey;
            }
        }
        span {
            position: relative;
            top: 2px;
        }
    }
    .caption {
        font-weight: 500;
        font-size: 0.875rem;
    }
    .subCaption {
        font-size: 0.75rem;
        line-height: 1rem;
        margin-bottom: 16px;
    }
    .downArrow {
        transform: rotate(90deg);
        path {
            stroke: $textGrey;
        }
    }
    .settingsItem {
        label {
            color: $textGrey;
            font-weight: 500;
        }
    }
    .notFound {
        text-align: center;
        padding: 18px;
        color: $textLight;
        font-size: 0.875rem;
    }
}
