
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.spaceTitle {
    margin-bottom: 40px;
}

.link {
    padding: 0;
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.doctorListWrapper {
    margin-top: 20px;
}