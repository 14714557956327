
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.contentWrapper {
    background-color: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 16px;
    margin: 24px auto;
    padding: 40px;
    max-width: 952px;
}

.divider {
    background-color: $linesSeparator;
    width: 1px;
    height: 100%;
}

.left {
    padding-right: 40px;
}

.title {
    color: $textMain;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    margin-bottom: 40px;
}

.label {
    color: $textGrey;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.diagnostic-wrapper {
    .top-label {
        color: $textMain;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
    }
}

.selectors-content {
    margin-bottom: 40px;
}

.diagnostic-configuration {
    margin: auto;
    width: 320px;
    margin-bottom: 40px;
    position: relative;
}

.diagnostic {
    background-color: $bgLightGrey;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    padding: 24px 8px;
    color: $border;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: 205px;
    margin: auto;

    &.active {
        background-color: $white;
        color: $textMain;
        padding: 0;
    }
}

.diagnosticResultText {
    color: $textMain !important;
}
