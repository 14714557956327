
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
}

.dinoBlock {
    position: absolute;
    top: -50px;
    right: 20px;

    .bubble {
        position: absolute;
        max-width: 150px;
        transform: rotate(45deg);
        top: 30px;
        left: -155px;

        .title {
            position: absolute;
            top: 35px;
            left: 32px;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #505862;
            text-align: center;
            transform: rotate(-20deg);
            z-index: 1;
        }

        svg {
            transform: rotate(155deg);
            path {
                fill: $bgLightGrey;
            }
        }
    }
}

.progress {
    margin-top: 16px;

    :global {
        .ant-progress-steps-item {
            width: 48px !important;
            height: 8px !important;
            background-color: $linesSeparator;

            &:first-child {
                border-radius: 8px 0 0 8px;
            }

            &:last-child {
                border-radius: 0 8px 8px 0;
            }
        }

        .ant-progress-steps-item-active {
            background-color: $accentMain;
        }
    }
}

.progressWrapper {
    display: flex;
    align-items: center;
    margin-top: 32px;

    .iconWrapper {
        background-color: $white;
        box-shadow: 0px 4px 24px rgba(24, 41, 82, 0.06);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        padding: 16px 1px;
        width: 80px;
        height: 80px;
    }
}

.slider {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 64px;
    min-height: 145px;
}

.chip {
    background-color: $someBg;
    border-radius: 16px;
    padding: 16px 24px;
    font-size: 14px;
    color: $textGrey;

    &.cancelled {
        background-color: $redLight;
    }

    .title {
        font-weight: 500;
        color: $textMain;
        font-size: 14px;
        line-height: 24px;
        margin-top: 4px;
        margin-bottom: 0;
    }

    .subtitle {
        font-size: 12px;
        font-weight: 500;
        color: $textMain;
    }

    .date {
        font-size: 12px;
    }

    .link-hover {
        display: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $accentMain;
        text-decoration: none;
    }

    .list {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
    }

    &.today {
        background-color: $blueVerifyLight;
    }

    &:hover {
        .link-hover {
            display: block;
        }
    }
}
