
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 40%;
    max-width: 400px;
    min-width: 240px;
    margin: auto;
    text-align: center;
}

.contentLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin-bottom: 2rem;
        strong {
            display: block;
            font-weight: 500;
        }
    }
}
.txt {
    display: inline-block;
    font-weight: 400 !important;
    font-size: 0.75rem;
    padding: 10px;
}
.txtlink {
    color: $primary;
}
.bottomTxt {
    margin-top: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.commonErrorWrapper {
    position: relative;
    .commonError {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 10px;
        text-align: center;
        color: $red;
        font-size: 0.75rem;
    }
}

.error {
    border-color: $red;
}
