
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $textGrey;
}

.fullName {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.employeeRow {
    :global {
        .ant-pagination {
            background: white;
            justify-content: start;
            align-items: center;
            margin: 0 !important;
            padding: 16px;

            li:first-child {
                margin-right: auto;
            }

            li:last-child {
                margin-left: auto;
            }

            .ant-pagination-total-text {
                font-weight: 500;
                font-size: 14px;
                color: $textMain;
            }

            .ant-pagination-options {
                margin: 0;
            }

            .ant-pagination-options .ant-select-selector {
                /* Body/14 Bold */
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                color: $accentMain;
                border: none;
            }
        }
    }
}

.employeeRow tr:hover td {
    background: $bgLightGrey;
    border-bottom: 1px solid $bgLightBlue;
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
    cursor: pointer;
}
